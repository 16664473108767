import React from 'react';
import { DmcaNoticeType } from '../../../redux/contactUs/types';

/**
 * The type of DMCA notice the user is sending changes what fields are required,
 * so this context will help us handle detecting if a field is required
 * directly inside said field's component.
 */
export const DmcaNoticeTypeContext = React.createContext<DmcaNoticeType>(
  DmcaNoticeType.Holder
);
