import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name'> {}

const UnderstandsNotificationForwardingCheckbox: React.FC<Props> = props => (
  <FormCheckbox
    id="understandsComplaintForwarding"
    name="understandsComplaintForwarding"
    {...props}
  />
);

UnderstandsNotificationForwardingCheckbox.displayName =
  'UnderstandsNotificationForwardingCheckbox';

export default UnderstandsNotificationForwardingCheckbox;
