import React from 'react';
import SiteFooter from '@wix/da-shared-react/pkg/SiteFooter';
import SiteHeader from '@wix/da-shared-react/pkg/SiteHeader';
import PageContent from '../PageContent';
import s from './Page.scss';

interface Props {}

const Page: React.FC<Props> = () => {
  return (
    <>
      <SiteHeader />
      <div className={s['content-container']}>
        <PageContent className={s['page-content']} />
        <SiteFooter />
      </div>
    </>
  );
};
Page.displayName = 'Page';

export default Page;
