import React, { useContext } from 'react';
import c from 'classnames';
import RadioButton, {
  RadioButtonProps,
  RadioButtonContext,
} from '@wix/da-a11y/pkg/components/formControls/RadioButton';
import s from './IssueCategoryCard.scss';

interface Props
  extends Omit<
    RadioButtonProps,
    'name' | 'hasAccessibleText' | 'aria-label' | 'aria-labelledby' | 'children'
  > {
  subtext: React.ReactNode;
}

const Content: React.FC<Pick<Props, 'subtext' | 'title'>> = ({
  title,
  subtext,
}) => {
  const isSelected = useContext(RadioButtonContext);
  return (
    <div className={c(s['content'], isSelected && s['selected'])}>
      <div className={s['title']}>{title}</div>
      {subtext && <div className={s['subtext']}>{subtext}</div>}
    </div>
  );
};

const IssueCategoryCard: React.FC<Props> = ({ title, subtext, ...rest }) => {
  return (
    <RadioButton
      className={s['root']}
      name="root-issue-category"
      hasAccessibleText
      {...rest}
    >
      <Content title={title} subtext={subtext} />
    </RadioButton>
  );
};
IssueCategoryCard.displayName = 'IssueCategoryCard';

export default IssueCategoryCard;
