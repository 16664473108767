import { ContactUsStoreSlice } from './types';
import { reduxKey } from './constants';

function getState(state: any): ContactUsStoreSlice {
  if (!state[reduxKey]) {
    return {} as any;
  }
  return state[reduxKey];
}

export function getRawMenuData(state) {
  return getState(state).rawMenuData;
}

export function getInitialMenuItemId(state) {
  return getState(state).initialMenuItemId;
}

export function getCountries(state) {
  return getState(state).countries;
}

export function getDmcaFormImageUploads(state) {
  return getState(state).dmcaFormImageUploads;
}

export function getContactFormImageUpload(state) {
  return getState(state).contactFormImageUpload;
}

export function getFormSubmissionStatus(state) {
  return getState(state).formSubmissionStatus;
}
