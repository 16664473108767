import { TFunction } from 'i18next';
import {
  PapiOrder,
  PapiOrderV2,
  PapiSubproduct,
  PapiUser,
  PapiDacheckoutPurchase,
  PapiRequestDacheckoutPurchase,
} from '@wix/da-papi-types';
import DeviationMediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { formatDollars } from '@wix/da-shared-react/pkg/utils/formatting';
import { requestNapiAsync } from '@wix/da-http-client/pkg/async';
import {
  PaymentMethod,
  CreditCardType,
  ProductType,
  AdoptablePurchaseType,
} from '../types';
import { BiData, PaymentButtonClickBiEvent } from '@wix/da-bi/pkg/events';
import { API_BASE_PATH } from '../../constants';

export const getOrderTotalPrice = (
  method: PaymentMethod,
  order: PapiOrder | PapiOrderV2
) => {
  return method === PaymentMethod.POINTS ? order.totalPoints : order.total;
};

export const getOrderSubproductPriceBeforeDiscount = (
  method: PaymentMethod,
  order: PapiOrder | PapiSubproduct
) => {
  return method === PaymentMethod.POINTS
    ? order.pointsPriceBeforeDiscount ?? order.pricePoints
    : order.priceBeforeDiscount ?? order.price;
};

export const getOrderDiscount = (method: PaymentMethod, order: PapiOrder) => {
  return method === PaymentMethod.POINTS
    ? order.discountPoints
    : order.discount;
};

export const formatPrice = (
  t: TFunction,
  method: PaymentMethod,
  price: number
) => {
  return method === PaymentMethod.POINTS
    ? t('price.points', {
      price: Number(price).toLocaleString(),
    })
    : t('price.usd', {
      price: formatDollars(price),
    });
};

// Code ported from green site
export const getCreditCardType = (
  number: string
): CreditCardType | undefined => {
  if (!number || !number.match(/^\d+$/)) {
    return undefined;
  }
  if (number[0] === '4' && (number.length === 13 || number.length === 16)) {
    return CreditCardType.VISA;
  }
  const prefix2 = number.substr(0, 2);
  if ((prefix2 === '34' || prefix2 === '37') && number.length === 15) {
    return CreditCardType.AMEX;
  }
  const prefix2int = parseInt(prefix2, 10);
  if (
    ((prefix2int >= 51 && prefix2int <= 55) ||
      (prefix2int >= 22 && prefix2int <= 27)) &&
    number.length === 16
  ) {
    return CreditCardType.MASTERCARD;
  }
  if (number.substr(0, 4) === '6011' && number.length === 16) {
    return CreditCardType.DISCOVER;
  }
};

export const isValidCreditCardNumber = (number: string) => {
  number = sanitizeCreditCardNumber(number);
  if (!getCreditCardType(number)) {
    return false;
  }
  // Luhn check, code ported from green site
  let sum = 0;
  for (let i = number.length - 1; i >= 0; i -= 2) {
    sum += parseInt(number[i], 10);
  }
  for (let i = number.length - 2; i >= 0; i -= 2) {
    let doubled = parseInt(number[i], 10) * 2;
    if (doubled >= 10) {
      doubled -= 9;
    }
    sum += doubled;
  }
  return sum % 10 === 0;
};
export const sanitizeCreditCardNumber = (number: string) => {
  return number.replace(/[^0-9]/g, '');
};

export const getOrderedProductType = (
  order: PapiOrder | PapiSubproduct
): ProductType => {
  if (
    order.deviation &&
    DeviationMediaService.getDeviationType(order.deviation) ===
    DeviationTypes.COMMISSION
  ) {
    return ProductType.COMMISSION;
  }
  if (order.deviation && order.deviation.pcp) {
    return ProductType.PCP;
  }
  if (order.deviation && order.deviation.tier) {
    return ProductType.MEMBER_SUBSCRIPTION;
  }
  if (order.gallery) {
    return ProductType.PREMIUM_FOLDER;
  }
  if (order.corePackage) {
    return ProductType.CORE_PACKAGE;
  }
  if (order.deviation && order.deviation.adoptable) {
    return ProductType.ADOPTABLE;
  }
  return ProductType.OTHER;
};

export const getAdoptablePurchaseType = (
  order: PapiOrder | PapiSubproduct
): AdoptablePurchaseType | null => {
  switch (order.subproductName) {
    case 'Adoptables Offers':
      return AdoptablePurchaseType.OFFER;

    case 'Adoptables Fixed Price':
      return AdoptablePurchaseType.FIXED_PRICE;

    default:
      return null;
  }
};

const getFlowType = (
  isCorePurchase: boolean,
  hasCoreMembership: boolean,
  isGift: boolean
) => {
  if (!isCorePurchase) {
    return undefined;
  }

  if (isGift) {
    return 'give_core';
  }

  if (hasCoreMembership) {
    return 'upgrade_core';
  }

  return 'become_core';
};

export const getPayBiData = (
  order: PapiOrder | PapiSubproduct,
  paymentMethod: PaymentMethod,
  hasCoreMembership: boolean,
  giftUser?: PapiUser
) => {
  const itemsIds = [order.subproductId]; // TODO: Support multiple items in cart
  const flowType = getFlowType(
    getOrderedProductType(order) === ProductType.CORE_PACKAGE,
    hasCoreMembership,
    giftUser !== undefined
  );

  return BiData<PaymentButtonClickBiEvent>({
    evid: 520,
    payment_type: paymentMethod as any,
    item_total: order.total, // In dollars
    num_items: itemsIds.length,
    subproductid: itemsIds.join(','),
    give_core_or_become_core: flowType as any,
    is_form_valid: 1,
  });
};

export function placeOrderAsync(data: PapiRequestDacheckoutPurchase, csrfToken: string): Promise<PapiDacheckoutPurchase> {
  // /purchase is still proxied through node so we call NAPI here
  return requestNapiAsync({
    method: 'post',
    baseURL: API_BASE_PATH,
    url: '/purchase',
    data,
  }, { csrfToken });
}
