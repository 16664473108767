import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRawMenuData } from '../../redux/contactUs/selectors';
import { BiDirectionalTree } from './utils/tree';

/**
 * Contains a {@link BiDirectionalTree} class instance that we can use to fetch relevant
 * issues, root categories, etc
 */
export const ContactTreeContext = React.createContext<
  BiDirectionalTree<{ contactEndpoint?: string }>
>(new BiDirectionalTree<{ contactEndpoint?: string }>([], () => ({})));

export const ContactTreeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const rawMenuData = useSelector(getRawMenuData);
  const tree: BiDirectionalTree<{ contactEndpoint?: string }> = useMemo(
    () =>
      new BiDirectionalTree(rawMenuData, rawDataEntry => ({
        contactEndpoint: rawDataEntry.contactEndpoint,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ContactTreeContext.Provider value={tree}>
      {children}
    </ContactTreeContext.Provider>
  );
};
