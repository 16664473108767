import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormTextInput from '../../partials/FormTextInput';
import FormSectionDescription from '../../partials/FormSectionDescription';
import FormSection from '../../partials/FormSection';
import FormSectionHeading from '../../partials/FormSectionHeading';
import { DmcaNoticeTypeContext } from '../noticeTypeContext';
import { DmcaNoticeType } from '../../../../redux/contactUs/types';
import CopyrightCounterCheckboxes from './CopyrightCounterCheckboxes';
import CopyrightRepresentativeCheckboxes from './CopyrightRepresentativeCheckboxes';
import CopyrightHolderCheckboxes from './CopyrightHolderCheckboxes';

interface Props {}

const DmcaLegalChecklistBlock: React.FC<Props> = () => {
  const { t } = useTranslation('contact');
  const noticeType = useContext(DmcaNoticeTypeContext);

  // TODO: Alter layout so that checkboxes can maintain their extra bottom spacing
  // otherwise, errors shift the layout of the form.
  return (
    <FormSection
      heading={
        <FormSectionHeading>
          {t('contact:dmcaForm.legalBlock.heading')}
        </FormSectionHeading>
      }
      description={
        <FormSectionDescription>
          {t('contact:dmcaForm.legalBlock.description')}
        </FormSectionDescription>
      }
    >
      {noticeType === DmcaNoticeType.Counter && <CopyrightCounterCheckboxes />}
      {noticeType === DmcaNoticeType.Representative && (
        <CopyrightRepresentativeCheckboxes />
      )}
      {noticeType === DmcaNoticeType.Holder && <CopyrightHolderCheckboxes />}

      <FormTextInput
        id="signature"
        name="signature"
        label={t('contact:dmcaForm.legalBlock.signature.label')}
        isRequired
      />
    </FormSection>
  );
};

DmcaLegalChecklistBlock.displayName = 'DmcaLegalChecklistBlock';

export default DmcaLegalChecklistBlock;
