import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import TextInput, {
  type TextInputProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import {
  ContactUsFormValues,
  DmcaFormValues,
} from '../../../../redux/contactUs/types';

interface Props
  extends Omit<
    TextInputProps,
    'autoComplete' | 'validationResult' | 'onBlur' | 'value' | 'noBottomSpacing'
  > {
  name: keyof ContactUsFormValues | keyof DmcaFormValues;
}

const FormTextInput: React.FC<Props> = ({
  name,
  isRequired,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const { control, errors, getValues } = useFormContext();
  const hasError = Boolean(errors?.[name]);
  const fieldValue = getValues(name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={fieldValue}
      rules={{
        required: isRequired,
      }}
      render={({ onChange: formProviderOnChange, onBlur, value }) => (
        <TextInput
          {...rest}
          autoComplete="off"
          validationResult={hasError ? 'error' : undefined}
          details={hasError ? t('common.form.errors.required') : undefined}
          value={value}
          onChange={ev => {
            formProviderOnChange(ev.target.value);
            onChange && onChange(ev);
          }}
          onBlur={onBlur}
          noBottomSpacing
          isRequired={isRequired}
        />
      )}
    />
  );
};
FormTextInput.displayName = 'FormTextInput';

export default FormTextInput;
