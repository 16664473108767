import { all, takeEvery, put } from 'redux-saga/effects';
import {
  logPageView,
  biModalUnload,
} from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { appMounted } from '../actions/app';
import { exitDuperbrowseDone } from '@wix/da-shared-react/pkg/Duperbrowse/redux/actionCreators';

export function* pageViewHandler({ payload: pageName }) {
  yield put(
    logPageView({
      component: pageName,
      view: 'shop',
    })
  );
}

export default function* biLogger() {
  yield all([
    takeEvery(
      [appMounted, exitDuperbrowseDone, biModalUnload],
      pageViewHandler
    ),
  ]);
}
