import React from 'react';
import FormCheckbox from '../../partials/FormCheckbox';

interface Props {
  required?: boolean;
}

const OwnerOrRepresentativeCheckbox: React.FC<Props> = ({ required }) => {
  return (
    <FormCheckbox
      id="isOwnerOrRepresentative"
      name="isOwnerOrRepresentative"
      label="I am the copyright owner, or an agent authorized to act on behalf of the copyright owner in an exclusive right allegedly being infringed."
      required={required}
    />
  );
};

OwnerOrRepresentativeCheckbox.displayName = 'OwnerOrRepresentativeCheckbox';

export default OwnerOrRepresentativeCheckbox;
