import React, { HTMLAttributes } from 'react';
import c from 'classnames';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './ContentBlockHeading.scss';

interface Props extends HTMLAttributes<HTMLHeadingElement> {}

const ContentBlockHeading: React.FC<Props> = ({ className, ...rest }) => (
  <Heading className={c(s['root'], className)} {...rest} />
);
ContentBlockHeading.displayName = 'ContentBlockHeading';

export default ContentBlockHeading;
