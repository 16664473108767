import React from 'react';
import { URLS } from '@wix/da-url';
import { GlobalConfirmationPopupProps } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import PaperAirplaneIllustration from '../../components/contactUs/partials/PaperAirplaneIllustration';

const noop = () => {};

export function getFormSubmittedModalProps(
  ticketUrl: string
): GlobalConfirmationPopupProps {
  return {
    icon: <PaperAirplaneIllustration />,
    titleTransKey: 'contact:contactForm.successModal.heading',
    textTransKey: 'contact:contactForm.successModal.description',
    confirmBtnLabelTransKey:
      'contact:contactForm.successModal.viewTicket.button.label',
    onConfirm: () => {
      window.location.href = URLS.HELP;
    },
    withCancelButton: !!ticketUrl,
    cancelBtnLabelTransKey:
      'contact:contactForm.successModal.back.button.label',
    onCancel: () => {
      window.location.href = ticketUrl;
    },
    // add a noop here so clicking outside the modal or on the 'X' will
    // only close the modal and not call onCancel
    onRequestClose: noop,
  };
}
