import React /* , { useEffect, useRef } */ from 'react';
import { useTranslation } from 'react-i18next';
import { CONTACT_PAGE_ANCHOR_JUMP_POINTS } from '@wix/da-url';
import FormDropdown from '@wix/da-ds/pkg/Dropdown/Preset/FormDropdown';
import { getAtIndex } from '../../utils/utils';
import { MenuTreeNode } from '../../../../redux/contactUs/types';
import ContentBlock from '../ContentBlock';
import ContentBlockHeading from '../ContentBlockHeading';

import s from './IssueDrilldownBlock.scss';

export interface Props {
  selectedNodes: MenuTreeNode[];
  selectedCategory: MenuTreeNode;
  dropdownItemSets: MenuTreeNode[][];
  contentRef?: React.Ref<HTMLDivElement>;
  onSelect: (node: MenuTreeNode) => void;
}

const IssueDrilldownBlock: React.FC<Props> = ({
  selectedNodes,
  selectedCategory,
  dropdownItemSets,
  contentRef,
  onSelect,
}) => {
  const { t } = useTranslation('contact');

  return (
    <ContentBlock
      heading={
        <ContentBlockHeading id={CONTACT_PAGE_ANCHOR_JUMP_POINTS.DRILLDOWN}>
          {t('contact:drilldownBlock.heading')}
        </ContentBlockHeading>
      }
      // TODO: Revisit the dropdownItemSets mapping, I feel like it can be cleaned up quite a bit
      content={
        <div className={s['content']} ref={contentRef}>
          {dropdownItemSets.map((dropdownItemSet, dropdownItemSetIndex) => {
            const relevantSelectedNode = getAtIndex(
              selectedNodes,
              dropdownItemSetIndex
            );

            return (
              <FormDropdown
                key={
                  dropdownItemSetIndex === 0
                    ? selectedCategory.id
                    : getAtIndex(selectedNodes, dropdownItemSetIndex - 1)?.id
                }
                initiallyOpen
                placeholder={t(
                  'contact:drilldownBlock.field.dropdown.placeholder'
                )}
                onSelect={value =>
                  onSelect(dropdownItemSet.find(item => item.id === value)!)
                }
                selectedValue={relevantSelectedNode?.id}
                items={dropdownItemSet.map(option => ({
                  value: option.id,
                  label: t(`contact:drilldownBlock.field.value.${option.id}`),
                  selected: relevantSelectedNode?.id === option.id,
                }))}
              />
            );
          })}
        </div>
      }
    />
  );
};
IssueDrilldownBlock.displayName = 'IssueDrilldownBlock';

export default IssueDrilldownBlock;
