import React from 'react';
import s from './FormSectionDescription.scss';

interface Props {
  children: React.ReactNode;
}

const FormSectionDescription: React.FC<Props> = ({ children }) => (
  <p className={s['root']}>{children}</p>
);

FormSectionDescription.displayName = 'FormSectionDescription';

export default FormSectionDescription;
