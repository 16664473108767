import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DmcaNoticeType } from '../../../../redux/contactUs/types';
import { DmcaNoticeTypeContext } from '../../DmcaFormBlock/noticeTypeContext';
import FormCheckbox from '../../partials/FormCheckbox';

interface Props {
  required?: boolean;
}

const AcceptsLiabilityCheckbox: React.FC<Props> = ({ required }) => {
  const { t } = useTranslation('contact');
  const noticeType = useContext(DmcaNoticeTypeContext);
  const label = t(
    noticeType === DmcaNoticeType.Counter
      ? 'contact:dmcaForm.legalBlock.counterClaim.acceptsLiability.label.counter'
      : 'contact:dmcaForm.legalBlock.counterClaim.acceptsLiability.label'
  );

  return (
    <FormCheckbox
      id="doesAcceptLiability"
      name="doesAcceptLiability"
      label={label}
      required={required}
    />
  );
};

AcceptsLiabilityCheckbox.displayName = 'AcceptsLiabilityCheckbox';

export default AcceptsLiabilityCheckbox;
