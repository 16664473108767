import escapeTextForBrowser from '@wix/da-shared-react/pkg/utils/xss/escapeTextForBrowser';
import { DmcaFormValues, DmcaNoticeType, DmcaFormImageUpload } from './types';

function fileToTicketContent(file: DmcaFormImageUpload) {
  let content = `
    Title of Work: ${file.title}\n
    Url of Infringing Deviation: ${file.infringingUrl}\n
  `;

  if (file.originalUrl) {
    content += `
      \n
      Link to Original Work: ${file.originalUrl}\n
    `;
  }

  if (file.uploadResult) {
    content += `
      \n
      Uploaded Copy\n
      Name: ${file.uploadResult.name}\n
      Url: ${file.uploadResult.url}\n
    `;
  }

  return content;
}

function filesToTicketContent(files?: DmcaFormImageUpload[]) {
  return files?.map(fileToTicketContent).join('\n\n--------\n\n');
}

export function generateDmcaTicketBody(
  formValues: DmcaFormValues,
  files: DmcaFormImageUpload[],
  noticeType: DmcaNoticeType
) {
  let legalInfo: string = '';
  if (
    noticeType === DmcaNoticeType.Holder ||
    noticeType === DmcaNoticeType.Representative
  ) {
    legalInfo = `
      I am the copyright owner, or an agent authorized to act on behalf of the copyright owner in an exclusive right allegedly being infringed.\n
      I have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.\n
      The information and assertions in this notification are accurate.\n
      I acknowledge that under Section 512(f) of the Digital Millennium Copyright Act any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability.\n
      I understand that DeviantArt may forward a copy of this complaint to the individual against whom I am claiming infringement.\n
      I acknowledge that knowingly filing a false complaint will result in the termination of my DeviantArt account.\n`;
  } else if (noticeType === DmcaNoticeType.Counter) {
    legalInfo = `
      I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.\n
      The information and assertions in this counter-notification are accurate.\n
      I acknowledge that under Section 512(f) of the Digital Millennium Copyright Act any person who knowingly materially misrepresents that material was removed or disabled by mistake or misidentification may be liable for damages.\n
      I consent to the jurisdiction of the Federal District Court for the district in which my address is located, of if my address is outside the United States, the judicial district in which DeviantArt is located (Central District of California), and will accept service of the process from the claimant.\n
      I understand that a copy of this counter-notification, including my address and telephone number, will be forwarded to the complaining party.\n
      I acknowledge that knowingly filing a false counter-notification will result in the termination of my DeviantArt account.\n
      I have been advised that a counter-notification leaves the individual or organization filing the initial claim no choice but to sue me if he/she/it disagrees with the counter-notice.\n`;
  }

  return `
    COPYRIGHT OWNER INFORMATION\n
    Copyright Holder Name: ${
      noticeType === DmcaNoticeType.Representative
        ? escapeTextForBrowser(formValues.copyrightHoldersName)
        : escapeTextForBrowser(formValues.name)
    }\n
    ${
      noticeType === DmcaNoticeType.Representative
        ? `Authority for Complaint: ${formValues.authorityForComplaint}\n`
        : ''
    }
    Name: ${escapeTextForBrowser(formValues.name)}\n
    Email: ${escapeTextForBrowser(formValues.email)}\n
    Phone Number: ${escapeTextForBrowser(formValues.phone)}\n
    Fax Number: ${escapeTextForBrowser(formValues.fax)}\n
    Street Address: ${escapeTextForBrowser(formValues.streetAddress)}\n
    Apt/Unit: ${escapeTextForBrowser(formValues.apartment)}\n
    City: ${escapeTextForBrowser(formValues.city)}\n
    State/Province: ${escapeTextForBrowser(formValues.state)}\n
    Zip Code: ${escapeTextForBrowser(formValues.zip)}\n
    Country: ${escapeTextForBrowser(formValues.country)}\n
    *********************************************
    CONTENT TO BE REMOVED:\n
    ${filesToTicketContent(files)}\n
    *********************************************
    Legal Verification:\n
    I certify under PENALTY OF PERJURY that:\n
    ${legalInfo}
    *********************************************
    Digital Signature: ${escapeTextForBrowser(formValues.signature)}\n
    `;
}
