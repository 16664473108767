import React from 'react';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import DrawerLoadable from '@wix/da-feedback-shared/pkg/Drawer/DrawerLoadable';
import FeedbackPageLoadable from '@wix/da-feedback-shared/pkg/FeedbackPage/FeedbackPageLoadable';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { submitModalRenderer } from '@wix/da-submit-shared/pkg/project-tools';
import Page from '../Page';
import { ContactTreeProvider } from '../treeContext';
import './App.scss';

interface Props {
  language: string;
  environment?: RuntimeEnvironment;
}

const App: React.FC<Props> = ({ language, environment }) => {
  return (
    <DaApp
      language={language}
      environment={environment}
      ncContextProps={{
        PageComponent: FeedbackPageLoadable,
        DrawerComponent: DrawerLoadable,
      }}
      modalsRenderFunc={submitModalRenderer}
    >
      <ContactTreeProvider>
        <Page />
      </ContactTreeProvider>
    </DaApp>
  );
};
App.displayName = 'App';

export default App;
