import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DmcaNoticeType } from '../../../../redux/contactUs/types';
import MultiFileUploadField from '../../formFields/MultiFileUploadField';
import FormSection from '../../partials/FormSection';
import FormSectionHeading from '../../partials/FormSectionHeading';
import { DmcaNoticeTypeContext } from '../noticeTypeContext';

interface Props {}

const DmcaInfringedWorkBlock: React.FC<Props> = () => {
  const { t } = useTranslation('contact');
  const noticeType = useContext(DmcaNoticeTypeContext);
  return (
    <FormSection
      heading={
        <FormSectionHeading>
          {t(
            noticeType === DmcaNoticeType.Counter
              ? 'contact:dmcaForm.infringingWork.heading.counter'
              : 'contact:dmcaForm.infringingWork.heading'
          )}
        </FormSectionHeading>
      }
    >
      <MultiFileUploadField />
    </FormSection>
  );
};

DmcaInfringedWorkBlock.displayName = 'DmcaInfringedWorkBlock';

export default DmcaInfringedWorkBlock;
