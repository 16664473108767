import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name'> {}

const AccurateInformationCheckbox: React.FC<Props> = props => {
  return (
    <FormCheckbox
      id="isInformationAccurate"
      name="isInformationAccurate"
      {...props}
    />
  );
};

AccurateInformationCheckbox.displayName = 'AccurateInformationCheckbox';

export default AccurateInformationCheckbox;
