import { Url } from '@wix/da-url';

const sisuUrls = [Url.loginLink(), Url.joinLink()];

function isLoginOrJoinUrl(url: string) {
  return sisuUrls.some(
    sisuUrl =>
      url.startsWith(sisuUrl) || url.startsWith(sisuUrl.replace('.com', '.lan'))
  );
}

export const getBackPageFromReferer = (
  referer: string = '',
  props?: { foruserid?: number; subproductId?: number }
) => {
  const { foruserid, subproductId } = props || {};
  let url: string | undefined;
  let titleKey: string | undefined;

  if (/core-membership/.test(referer)) {
    const qs = referer.split('?')[1];

    url = `${Url.coreMembershipLink(undefined, {
      subproductId,
      forUserId: foruserid,
    })}&${qs}`;

    titleKey = 'checkout.back.packagePicker';
  } else if (isLoginOrJoinUrl(referer)) {
    return undefined;
  } else if (/^(?!\s*$).+/.test(referer)) {
    url = referer;
    titleKey = 'checkout.back.previousPage';
  } else {
    return undefined;
  }

  if (typeof document !== 'undefined') {
    if (url === document.URL) {
      // Do not link back to self
      return undefined;
    }
  }

  return {
    url,
    titleKey,
  };
};
