import { createAction } from 'redux-actions';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';
import { PapiOrderV2 } from '@wix/da-papi-types';

export const initializeOrders = createAction(
  'POINTS.INIT_ORDERS',
  (streamData: {
    hasMore?: boolean;
    nextOffset?: number | null;
    results?: PapiOrderV2[];
  }) => streamData
);
export const fetchNextOrders = createCallbackActionCreator(
  'POINTS.FETCH_NEXT_ORDERS'
);
