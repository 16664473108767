import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name'> {}

const GoodFaithCheckbox: React.FC<Props> = props => {
  return (
    <FormCheckbox
      id="hasGoodFaithBelief"
      name="hasGoodFaithBelief"
      {...props}
    />
  );
};

GoodFaithCheckbox.displayName = 'GoodFaithCheckbox';

export default GoodFaithCheckbox;
