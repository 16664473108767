import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name' | 'label'> {}

const UnderstandsSueabilityCheckbox: React.FC<Props> = props => (
  <FormCheckbox
    id="understandsSueability"
    name="understandsSueability"
    label="I have been advised that a counter-notification leaves the individual or organization filing the initial claim no choice but to sue me if he/she/it disagrees with the counter-notice."
    {...props}
  />
);

UnderstandsSueabilityCheckbox.displayName = 'UnderstandsSueabilityCheckbox';

export default UnderstandsSueabilityCheckbox;
