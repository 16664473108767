import { BiDirectionalTreeNode } from '../../components/contactUs/utils/tree';
import { reduxKey } from './constants';

/** Represents an entry in our contact form drilldown process. */
export interface FlatMenuItem {
  id: string;
  /** The id of the menu item that must be selected before this one is visible. */
  parentId: string | null;
  contactEndpoint?: string;
}

interface MenuTreeNodeData {
  contactEndpoint?: string;
}
export type MenuTreeNode = BiDirectionalTreeNode<MenuTreeNodeData>;

/**
 * We submit DMCA tickets in three main categories. This enum reflects that.
 */
export enum DmcaNoticeType {
  Holder = 'holder',
  Representative = 'representative',
  Counter = 'counter',
}

/**
 * Contains two representations of user uploaded files.
 *
 * One is used for uploading to our servers, the other is used for a faster file preview.
 */
export interface FormFileValue {
  fileObject?: File;
  fileBlob?: string;
}

export interface DmcaFormValues {
  email: string;
  copyrightHoldersName: string;
  authorityForComplaint: string;
  name: string;
  phone: string;
  fax: string;
  streetAddress: string;
  apartment: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  // dmca legal checkboxes
  isOwnerOrRepresentative: boolean;
  hasGoodFaithBelief: boolean;
  isInformationAccurate: boolean;
  doesAcceptLiability: boolean;
  understandsComplaintForwarding: boolean;
  acceptsPossibleTermination: boolean;

  // dmca counter checkboxes
  consentsToJurisdiction: boolean;
  understandsSueability: boolean;
  doesAcceptPossibleTermination: boolean;

  signature: string;
}

export interface ContactUsFormValues {
  title: string;
  issueDescription: string;
}

export enum ImageUploadStatus {
  /**
   * This value means the form field is visible, but the user hasn't tried to upload to it
   * yet.
   */
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Failure = 'Failure',
  Success = 'Success',
}

export interface ContactFormImageUpload {
  fileName?: string;
  previewDataUrl?: string;

  uploadResult?: {
    name: string;
    url: string;
  };
  status: ImageUploadStatus;
}

export interface DmcaFormImageUpload {
  entryUuid: string;
  infringingUrl: string;

  title: string;
  originalUrl: string;

  fileName?: string;
  previewDataUrl?: string;

  uploadResult?: {
    name: string;
    url: string;
  };
  status: ImageUploadStatus;
}

export enum FormSubmissionStatus {
  /**
   * The user is still filling out the form or hasn't gotten to it yet.
   */
  AwaitingSubmission = 'Awaiting Submission',

  /**
   * User has clicked the submit button and the data passed validation.
   */
  InProgress = 'In Progress',

  Success = 'Success',
  Failure = 'Failure',
}

export interface ContactUsStoreSlice {
  /**
   * The menu items we get from the backend, stored as is. Storing these here makes it easier to handle
   * server/client rendering. We don't use this field directly except for generating the initial tree structure inside
   * `ContactTreeContext`
   */
  rawMenuData: FlatMenuItem[];
  initialMenuItemId?: string;

  contactFormImageUpload: ContactFormImageUpload;
  dmcaFormImageUploads: { [uuid: string]: DmcaFormImageUpload };

  formSubmissionStatus: FormSubmissionStatus;

  countries: {
    countryId: number;
    countryName: string;
  }[];
}

export interface ContactUsStoreState {
  [reduxKey]: ContactUsStoreSlice;
}
