import { Middleware } from 'redux';
import { IModuleStore } from 'redux-dynamic-modules-core';
import { rootModuleStoreFactory } from '@wix/da-shared-react/pkg/redux/rootModuleStore/factory';
import rootReducer from '../rootReducer';
import rootSaga from '../rootSaga';
import { AppState } from '../../types/store';
import logger from '../../helpers/logger';
import { AppEnvironmentSlice } from '@wix/da-react-app/pkg/client/Environment/redux/types';

export function configureStore(
  initialState: AppState & AppEnvironmentSlice
): IModuleStore<AppState> {
  const middleware: Middleware[] = [];

  return rootModuleStoreFactory<AppState>(
    middleware,
    logger,
    initialState,
    rootReducer,
    rootSaga,
    false
  ) as IModuleStore<AppState>;
}
