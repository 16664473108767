import { PapiOrderV2, PapiSubscription } from '@wix/da-papi-types';
import { AppState } from '../types/store';

export const getCards = (state: AppState) => state?.account?.cards ?? [];

export const getAddresses = (state: AppState) =>
  state?.account?.addresses ?? [];

export const getOrders = (state: AppState) => state?.account?.orders ?? [];

export const getOrderByOrderId = (
  state: AppState,
  orderId: number
): PapiOrderV2 | undefined => getOrders(state).find(o => o.orderId === orderId);

export const getCoreSubscription = (state: AppState) =>
  state?.account?.coreSubscription;

export const getSubscriptions = (state: AppState): PapiSubscription[] =>
  state?.account?.subscriptions ?? [];

export const getActiveSubscriptions = (state: AppState): PapiSubscription[] =>
  getSubscriptions(state).filter(s => s.isActive);

export const getSubscriptionByTierId = (
  state: AppState,
  tierId: number
): PapiSubscription | undefined =>
  getSubscriptions(state).find(s => s.deviation?.deviationId === tierId);

export const getSubscriptionByOrderId = (
  state: AppState,
  orderId: number
): PapiSubscription | undefined => {
  const coreSubscription = getCoreSubscription(state);
  return coreSubscription?.orderId === orderId
    ? coreSubscription
    : getSubscriptions(state).find(s => s.orderId === orderId);
};

export const getInitialTierId = (state: AppState) =>
  state.account?.initialTierId;

export const getCountries = (state: AppState) =>
  state?.account?.countries ?? [];

export const getStates = (state: AppState) => state?.account?.states ?? [];
