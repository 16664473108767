import { DmcaNoticeType } from '../../../redux/contactUs/types';

export function getAtIndex<ArrayType>(
  array: ArrayType[],
  index: number
): ArrayType | undefined {
  return array.length > index ? array[index] : undefined;
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      // Validation. Reject if file:
      // 1. Is larger than 5MB
      if (file.size * 10 ** -6 > 5) {
        reject();
      } else {
        resolve(fileReader.result as string);
      }
    });
    fileReader.addEventListener('error', () => {
      reject();
    });
    fileReader.readAsDataURL(file);
  });
}

export function topicIdToDmcaNoticeType(topicId: string): DmcaNoticeType {
  switch (topicId) {
    case 'copyright_dmca_representative':
    case 'copyright_dmca_other':
      return DmcaNoticeType.Representative;
    case 'copyright_dmca_counter':
      return DmcaNoticeType.Counter;
    case 'copyright_dmca_holder':
    default:
      return DmcaNoticeType.Holder;
  }
}
