import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import s from './FormSection.scss';

interface Props {
  heading: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

const FormSection: React.FC<Props> = ({
  heading,
  description,
  children,
  className,
}) => (
  <Section className={c(s['root'], className)}>
    <div className={s['heading-and-description']}>
      {heading}
      {description}
    </div>
    <div className={s['content']}>{children}</div>
  </Section>
);

FormSection.displayName = 'FormSection';

export default FormSection;
