import React, { useCallback, useContext } from 'react';
import c from 'classnames';
import first from 'lodash/first';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRestrictToLoggedIn } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedIn';
import { Url } from '@wix/da-url';
import { getInitialMenuItemId } from '../../../redux/contactUs/selectors';
import { topicIdToDmcaNoticeType } from '../utils/utils';
import IssueDrilldownBlock from '../partials/IssueDrilldownBlock';
import IssueCategoriesBlock from '../partials/IssueCategoriesBlock';
import IssueResultBlock from '../partials/IssueResultBlock';
import ContactFormBlock from '../ContactFormBlock';
import DmcaFormBlock from '../DmcaFormBlock';
import StepperContentStage from '../partials/StepperContentStage';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { ContactTreeContext } from '../treeContext';
import {
  Stage,
  selectCategory,
  setLastSelectedMenuItem,
  showForm,
  useStepperState,
} from './localState';
import usePageContentAutoScrolling from './usePageContentAutoScrolling';

import s from './PageContent.scss';

export interface Props {
  className?: string;
}

const PageContent: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation('contact');

  const initialMenuItemId = useSelector(getInitialMenuItemId);
  const tree = useContext(ContactTreeContext);
  const [localState, dispatch] = useStepperState(
    initialMenuItemId ? tree.getNodeById(initialMenuItemId) : undefined
  );
  const lastSelectedItem = localState.lastSelectedItem;
  const hierarchy = lastSelectedItem ? tree.walkBack(lastSelectedItem) : [];
  const dropdownItemSets = hierarchy.reduce(
    (accumulator, node) =>
      node.isLeafNode
        ? accumulator
        : [...accumulator, tree.getNodeChildren(node)],
    []
  );

  const handleCategorySelect = useCallback(
    treeNode => {
      dispatch(selectCategory(treeNode));
    },
    [dispatch]
  );

  const handleIssueDropdownSelect = useCallback(
    node => dispatch(setLastSelectedMenuItem(node)),
    [dispatch]
  );

  const { isLoggedIn, restrictToLoggedIn } = useRestrictToLoggedIn();
  const showFormTrigger =
    !localState.showingForm &&
    localState.stage !== Stage.ReachedLeafWithoutForm;
  const recoveryEmailHref = `mailto:${t('contact:page.accountRecovery.email')}`;
  const emailLink = (
    <a className="text-link-level-1-brand" href={recoveryEmailHref}>
      {t('contact:page.accountRecovery.email')}
    </a>
  );

  const handleShowForm = useCallback(() => dispatch(showForm()), [dispatch]);

  // as selections are made, scroll to the next stage/control
  const { responseStageRef, drilldownContentRef, formStageRef, scrollToForm } =
    usePageContentAutoScrolling({
      stage: localState.stage,
      drilldownsSelected: hierarchy.length - 1,
      drilldownsAvailable: dropdownItemSets.length,
      showingForm: localState.showingForm,
    });

  return (
    <div className={c(s['root'], className)}>
      <div className={s['heading-section']}>
        <h1 className={s['heading']}>{t('contact:page.heading')}</h1>
        <p className={s['description']}>
          {t('contact:page.description')}
          {!isLoggedIn && (
            <>
              {' '}
              {t('contact:page.noteForLoggedOut', {
                postProcess: ['reactPostprocessor', 'featureBrancher'],
                loginLink: (
                  <a className="text-link-level-1-brand" href={Url.loginLink()}>
                    {t('contact:page.loginLink')}
                  </a>
                ),
              })}
            </>
          )}
        </p>
      </div>

      <div className={s['blocks']}>
        <StepperContentStage
          bubbleValue={
            localState.stage > Stage.AwaitingCategorySelection ? 'check' : 1
          }
          content={
            <IssueCategoriesBlock
              categories={tree.getRootNodes()}
              selectedCategory={first(hierarchy)}
              onSelect={handleCategorySelect}
            />
          }
        />

        {localState.stage >= Stage.DrillingDown && (
          <StepperContentStage
            bubbleValue={localState.stage > Stage.DrillingDown ? 'check' : 2}
            content={
              <IssueDrilldownBlock
                selectedCategory={hierarchy[0]}
                selectedNodes={hierarchy.slice(1)}
                onSelect={handleIssueDropdownSelect}
                dropdownItemSets={dropdownItemSets}
                contentRef={drilldownContentRef}
              />
            }
          />
        )}

        {localState.stage > Stage.DrillingDown && (
          <StepperContentStage
            ref={responseStageRef}
            bubbleValue={localState.showingForm ? 'check' : 3}
            content={
              lastSelectedItem && (
                <IssueResultBlock topicId={lastSelectedItem.id} />
              )
            }
            footnote={
              showFormTrigger && (
                <span>
                  {/* form requires user to be logged-in, so don't show trigger for login problems */}
                  {!isLoggedIn && isUnableToLogin(lastSelectedItem?.id) ? (
                    t('contact:page.accountRecovery.message', {
                      postProcess: ['reactPostprocessor', 'featureBrancher'],
                      emailLink,
                    })
                  ) : (
                    <>
                      {t('contact:page.formBlockTrigger.foreword')}{' '}
                      <TextButton
                        variant="approval-green"
                        size="small"
                        onClick={restrictToLoggedIn(
                          ReferralType.CONTACT_US,
                          handleShowForm,
                          Url.contactLink()
                        )}
                      >
                        {isLoggedIn
                          ? t('contact:page.formBlockTrigger.label')
                          : t('contact:page.formBlockTrigger.label.loggedout')}
                      </TextButton>
                    </>
                  )}
                </span>
              )
            }
          />
        )}

        {localState.showingForm && (
          <StepperContentStage
            ref={formStageRef}
            bubbleValue={4}
            content={
              localState.stage === Stage.ReachedLeafWithDmcaForm ? (
                lastSelectedItem && (
                  <DmcaFormBlock
                    selectedTopics={hierarchy}
                    noticeType={topicIdToDmcaNoticeType(lastSelectedItem.id)}
                    formRefCallback={scrollToForm}
                  />
                )
              ) : (
                <ContactFormBlock
                  selectedTopics={hierarchy}
                  formRefCallback={scrollToForm}
                />
              )
            }
          />
        )}
      </div>
    </div>
  );
};
PageContent.displayName = 'PageContent';

export default PageContent;

/** categories where telling the user to login would be wrong */
const MENU_ITEMS_WHERE_THE_USER_CANT_LOGIN = [
  'login_forgot_username',
  'login_forgot_password',
  'login_account_error',
];
/** since logged-out users can't successfully submit tickets, we need to know
 *  when we shouldn't display the button that opens the ticket form */
function isUnableToLogin(menuItem?: string): boolean {
  return !!menuItem && MENU_ITEMS_WHERE_THE_USER_CANT_LOGIN.includes(menuItem);
}
