import React from 'react';
import c from 'classnames';
import CheckIcon from '@wix/da-ds/pkg/Icons/16x16/Check';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './ProgressBubble.scss';

export interface Props {
  value: number | 'check';
  className?: string;
}

const ProgressBubble: React.FC<Props> = ({ className, value }) => {
  return (
    <div
      className={c(
        s['root'],
        value === 'check' && s['green-border'],
        className
      )}
    >
      {value === 'check' && (
        <CheckIcon disableDefaultColors size={IconSize.SMALLER} />
      )}

      {value !== 'check' && <span className={s['number']}>{value}</span>}
    </div>
  );
};
ProgressBubble.displayName = 'ProgressBubble';

export default ProgressBubble;
