import { useCallback, useEffect, useRef } from 'react';

const SCROLL_OPTIONS = {
  behavior: 'smooth' as ScrollBehavior,
  inline: 'start' as ScrollLogicalPosition,
};

/** in the contact-us page, when the user makes a selection, auto-scroll to the next control */
export default function usePageContentAutoScrolling({
  stage,
  drilldownsSelected,
  drilldownsAvailable,
  showingForm,
}) {
  // scroll to the sections when we change stage/steps
  const responseStageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (stage >= 3) {
      responseStageRef.current?.scrollIntoView(SCROLL_OPTIONS);
    }
  }, [stage]);

  // we've just added a new drilldown selector, scroll to center this element
  // (but not on the last - that's handled elsewhere)
  const drilldownContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      drilldownContentRef.current &&
      drilldownsSelected < drilldownsAvailable
    ) {
      drilldownContentRef.current.scrollIntoView(SCROLL_OPTIONS);
    }
  }, [drilldownsSelected, drilldownsAvailable]);

  // scroll to the form opener button when it appears
  const formStageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    showingForm && formStageRef.current?.scrollIntoView(SCROLL_OPTIONS);
  }, [showingForm]);

  const scrollToForm = useCallback(formNode => {
    formNode?.scrollIntoView({ ...SCROLL_OPTIONS, block: 'start' });
  }, []);

  return {
    responseStageRef,
    drilldownContentRef,
    formStageRef,
    scrollToForm,
  };
}
