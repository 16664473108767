import React from 'react';
import c from 'classnames';
import {
  ContactFormImageUpload,
  DmcaFormImageUpload,
  ImageUploadStatus,
} from '../../../../redux/contactUs/types';
import SuccessIcon from '@wix/da-ds/pkg/Icons/16x16/Check';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './FileUploadPreview.scss';

interface Props {
  uploadInformation: ContactFormImageUpload | DmcaFormImageUpload;
}

const FileUploadPreview: React.FC<Props> = ({ uploadInformation }) => {
  return (
    <div
      className={c(
        s['root'],
        uploadInformation.status === ImageUploadStatus.Failure && s['failure'],
        uploadInformation.status === ImageUploadStatus.Success && s['success']
      )}
    >
      <div
        className={c(
          s['uploading-overlay'],
          uploadInformation.status !== ImageUploadStatus.InProgress &&
            s['uploading-done']
        )}
      />
      <div className={s['image-wrapper']}>
        <img
          className={s['image']}
          src={uploadInformation.previewDataUrl}
          alt=""
        />
      </div>

      <p className={s['title']}>{uploadInformation.fileName}</p>

      <div className={s['status-indicator']} aria-live="polite">
        {uploadInformation.status === ImageUploadStatus.Failure && (
          // Since we're throwing this in the input label, we don't need to worry about retry logic.
          <div className={s['retry-text']}>
            Failed to upload{'\n'}click to retry
          </div>
        )}
        {uploadInformation.status === ImageUploadStatus.Success && (
          <SuccessIcon
            size={IconSize.SMALLER}
            disableDefaultColors
            aria-label="File upload succeeded!"
          />
        )}
      </div>
    </div>
  );
};

FileUploadPreview.displayName = 'FileUploadPreview';

export default FileUploadPreview;
