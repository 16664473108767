import { handleActions } from 'redux-actions';
import { PackagePickerStoreSlice } from './types';
import { initPackagePicker } from './actions';
import { reduxKey } from './constants';
import {
  PapiCorePackage,
  PapiDacheckoutCorePackages,
} from '@wix/da-papi-types';

const reducers = handleActions<PackagePickerStoreSlice, any>(
  {
    [`${initPackagePicker}`]: (
      state,
      action: ReturnType<typeof initPackagePicker>
    ) => {
      const { pickerMode, data } = action.payload;
      return {
        ...state,
        sale: data.sale as any, // missing heroImageAlt
        pickerMode,
        pickerStep: 'picking',
        packages: (data.packages || []) as PapiCorePackage[],
        packageBenefits: (data.packageBenefits ||
          []) as PapiDacheckoutCorePackages['packageBenefits'],
        packageConfig: (data.packageConfig ||
          []) as PapiDacheckoutCorePackages['packageConfig'],
        currentCoreSubscription: data.currentCoreSubscription,
        giftUser: data.giftUser,
        faqs: data.faqs,
        packageInfoBanner: data.packageInfoBanner,
      };
    },
  },
  {
    // Gibberish values
    pickerMode: 'buy',
    pickerStep: 'picking',
    packages: [],
  }
);

export default {
  [reduxKey]: reducers,
};
