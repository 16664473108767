import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageUploadStatus } from '../../../../redux/contactUs/types';
import { fileToBase64 } from '../../utils/utils';
import FileUploadPreview from '../../partials/FileUploadPreview';
import { uploadContactImage } from '../../../../redux/contactUs/actions';
import { getContactFormImageUpload } from '../../../../redux/contactUs/selectors';
import FileUploadPrompt from '../../partials/FileUploadPrompt';
import s from './FileUploadField.scss';

interface Props {}

const FileUploadField: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const imageUpload = useSelector(getContactFormImageUpload);
  // TODO: Image validation
  /* validate: ({ fileObject }: ContactFormFileValue) =>
            fileObject && fileObject.size * 10 ** -6 <= 5
              ? true
              : 'Sorry, but your file is too big. Please select one that is less than 5MB', */
  return (
    <label>
      <input
        className={s['real-input']}
        name="file"
        type="file"
        onChange={async event => {
          if (event.target.files && event.target.files.length > 0) {
            dispatch(
              uploadContactImage({
                fileObject: event.target.files[0],
                fileBlob: await fileToBase64(event.target.files[0]),
              })
            );
          }
        }}
      />
      {imageUpload.status === ImageUploadStatus.NotStarted && (
        <FileUploadPrompt />
      )}
      {imageUpload.status !== ImageUploadStatus.NotStarted && imageUpload && (
        <FileUploadPreview uploadInformation={imageUpload} />
      )}
    </label>
  );
};

FileUploadField.displayName = 'FileUploadField';

export default FileUploadField;
