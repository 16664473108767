export const XHR_BASE_PATH = '/_napi/da-ponies/';
export const API_BASE_PATH = `${XHR_BASE_PATH}api`;
export const SHARED_API_BASE_PATH = `${XHR_BASE_PATH}shared_api`;

export const BILLING_ORDERS_STREAM = 'BILLING_ORDERS_STREAM';
export const SALES_ORDERS_STREAM = 'SALES_ORDERS_STREAM';
export const POINTS_ORDERS_STREAM = 'POINTS_ORDERS_STREAM';

export const PACKAGE_PICKER_ENTRYPOINT_DEFAULT = 0;
export const PACKAGE_PICKER_ENTRYPOINT_DREAMUP = 1;
