import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectField from '@wix/da-ds/pkg/Dropdown/select/SelectField';
import { getCountries } from '../../../../redux/contactUs/selectors';

interface Props {
  isRequired?: boolean;
}

const CountryDropdown: React.FC<Props> = ({ isRequired }) => {
  const { t } = useTranslation('contact');
  const countries = useSelector(getCountries);

  const { control, errors = {} } = useFormContext();
  const { country: validationError } = errors;
  const defaultValidationErrorMsg = t(
    'contact:dmcaForm.submitterInfoBlock.field.countryDropdown.required'
  );
  const errorMessage = validationError
    ? validationError.message || defaultValidationErrorMsg
    : '';

  return (
    <Controller
      name="country"
      control={control}
      rules={{
        required: isRequired,
      }}
      render={({ onChange, value }) => (
        <SelectField
          id="country-input"
          value={value}
          items={countries.map(country => ({
            label: country.countryName,
            value: country.countryName,
          }))}
          onSelect={onChange}
          label={t(
            'contact:dmcaForm.submitterInfoBlock.field.countryDropdown.placeholder'
          )}
          isRequired={isRequired}
          error={errorMessage}
        />
      )}
    />
  );
};

CountryDropdown.displayName = 'CountryDropdown';

export default CountryDropdown;
