import React from 'react';
import { useTranslation } from 'react-i18next';
import OwnerOrRepresentativeCheckbox from '../../../formFields/OwnerOrRepresentativeCheckbox';
import GoodFaithCheckbox from '../../../formFields/GoodFaithCheckbox';
import AccurateInformationCheckbox from '../../../formFields/AccurateInformationCheckbox';
import AcceptsLiabilityCheckbox from '../../../formFields/AcceptsLiabilityCheckbox';
import UnderstandsNotificationForwardingCheckbox from '../../../formFields/UnderstandsNotificationForwardingCheckbox';
import AcceptsPossibleAccountTerminationCheckbox from '../../../formFields/AcceptsPossibleAccountTerminationCheckbox';

interface Props {}

const CopyrightRepresentativeCheckboxes: React.FC<Props> = () => {
  const { t } = useTranslation('contact');
  return (
    <>
      <OwnerOrRepresentativeCheckbox required />
      <GoodFaithCheckbox
        label={t(
          'contact:dmcaForm.legalBlock.representativeClaim.goodFaithCheckbox.label'
        )}
        required
      />
      <AccurateInformationCheckbox
        label={t(
          'contact:dmcaForm.legalBlock.representativeClaim.accurateInformationCheckbox.label'
        )}
        required
      />

      <AcceptsLiabilityCheckbox required />
      <UnderstandsNotificationForwardingCheckbox
        label={t(
          'contact:dmcaForm.legalBlock.representativeClaim.understandsNotificationForwardingCheckbox.label'
        )}
        required
      />
      <AcceptsPossibleAccountTerminationCheckbox
        label={t(
          'contact:dmcaForm.legalBlock.representativeClaim.acceptsPossibleAccountTermination.label'
        )}
        required
      />
    </>
  );
};

CopyrightRepresentativeCheckboxes.displayName =
  'CopyrightRepresentativeCheckboxes';

export default CopyrightRepresentativeCheckboxes;
