import { createAction } from 'redux-actions';
import { reduxKey } from './constants';
import { PapiDacheckoutCorePackages } from '@wix/da-papi-types';
import { PackagePickerStoreSlice } from './types';

export const initPackagePicker = createAction(
  `${reduxKey}.INIT_PACKAGE_PICKER`,
  (
    data: PapiDacheckoutCorePackages,
    pickerMode: PackagePickerStoreSlice['pickerMode']
  ) => ({ data, pickerMode })
);
