import React from 'react';
import s from './FormSectionSeparator.scss';

interface Props {}

const FormSectionSeparator: React.FC<Props> = () => {
  return <hr className={s['root']} />;
};

FormSectionSeparator.displayName = 'FormSectionSeparator';

export default FormSectionSeparator;
