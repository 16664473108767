import React from 'react';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './FormSectionHeading.scss';

interface Props {
  children: React.ReactNode;
}

const FormSectionHeading: React.FC<Props> = ({ children }) => {
  return <Heading className={s['root']}>{children}</Heading>;
};

FormSectionHeading.displayName = 'FormSectionHeading';

export default FormSectionHeading;
