import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BooleanInput, {
  BooleanInputProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/BooleanInput';
import {
  ContactUsFormValues,
  DmcaFormValues,
} from '../../../../redux/contactUs/types';

export interface Props
  extends Omit<BooleanInputProps, 'onChange' | 'noBottomSpacing'> {
  name: keyof ContactUsFormValues | keyof DmcaFormValues;
  required?: boolean;

  onChange?: BooleanInputProps['onChange'];
}

const FormCheckbox: React.FC<Props> = ({
  name,
  onChange,
  required,
  ...rest
}) => {
  const { t } = useTranslation();
  const { control, errors, getValues } = useFormContext();
  const hasError = Boolean(errors?.[name]);
  const fieldValue = getValues(name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={fieldValue}
      rules={{
        required,
      }}
      render={({ onChange: formProvidedOnChange, value }) => (
        <BooleanInput
          type="checkbox"
          onChange={(newValue, e) => {
            formProvidedOnChange(newValue);
            onChange && onChange(newValue, e);
          }}
          value={value}
          name={name}
          validationResult={hasError ? 'error' : undefined}
          details={hasError ? t('common.form.errors.required') : undefined}
          noBottomSpacing
          {...rest}
        />
      )}
    />
  );
};

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox;
