import { useForm } from 'react-hook-form';
import { DmcaFormValues } from '../../../redux/contactUs/types';

export function useDmcaForm() {
  return useForm<DmcaFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      fax: '',
      streetAddress: '',
      apartment: '',
      city: '',
      state: '',
      zip: '',
      country: '',

      acceptsPossibleTermination: false,
      isOwnerOrRepresentative: false,
      isInformationAccurate: false,
      hasGoodFaithBelief: false,
      doesAcceptLiability: false,
      doesAcceptPossibleTermination: false,
      understandsComplaintForwarding: false,
      understandsSueability: false,
      consentsToJurisdiction: false,
      signature: '',
    } as DmcaFormValues,
  });
}
