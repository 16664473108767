import { SagaIterator } from 'redux-saga';
import { select, all, takeEvery, put } from 'redux-saga/effects';
import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { initializeIfNeeded } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { getNextOffset } from '@wix/da-shared-react/pkg/Stream/withOffset/selectors';
import { getItemsPerFetch } from '@wix/da-shared-react/pkg/Stream/base/selectors';
import {
  takeStreamFetch,
  fetchStreamContents,
} from '@wix/da-shared-react/pkg/Stream/helpers';
import { POINTS_ORDERS_STREAM } from '../../constants';
import { initializeOrders, fetchNextOrders } from '../actions/points';
import { pointsStreamConfig } from '../helpers/streams';
import {
  PapiRequestDacheckoutPointsInit,
  PapiDacheckoutPointsInit,
} from '@wix/da-papi-types';

function* initializeOrdersSaga({
  payload: { hasMore, nextOffset, results },
}: ReturnType<typeof initializeOrders>) {
  const streamConfig: StreamInitConfig = {
    ...pointsStreamConfig,
    autoFetch: false,
    hasMore,
    nextOffset: nextOffset ?? undefined,
    items: results,
  };
  yield put(initializeIfNeeded(POINTS_ORDERS_STREAM, streamConfig));
}

function* fetchNextOrdersSaga({
  payload: { streamId },
}: ReturnType<typeof fetchNextOrders>) {
  const { offset, limit } = yield select(state => ({
    offset: getNextOffset(state, streamId),
    limit: getItemsPerFetch(state, streamId),
  }));
  yield fetchStreamContents<
    PapiRequestDacheckoutPointsInit,
    PapiDacheckoutPointsInit
  >({
    streamId,
    params: {
      offset: offset,
      limit: limit,
    },
    url: '/points/init',
    papiProject: 'dacheckout',
    getStreamData: result => ({
      hasMore: !!result.hasMore,
      items: result.transactions ?? result.orders ?? [],
      nextOffset: result.nextOffset ?? undefined,
    }),
  });
}

export default function* saga(): SagaIterator {
  yield all([
    takeEvery(initializeOrders, initializeOrdersSaga),
    takeStreamFetch(fetchNextOrders, fetchNextOrdersSaga),
  ]);
}
