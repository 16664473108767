import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormTextInput from '../../partials/FormTextInput';
import FormSectionHeading from '../../partials/FormSectionHeading';
import CountryDropdown from '../../formFields/CountryDropdown';
import FormSection from '../../partials/FormSection';
import { DmcaNoticeTypeContext } from '../noticeTypeContext';
import { DmcaNoticeType } from '../../../../redux/contactUs/types';

interface Props {}

const DmcaSubmitterInfoBlock: React.FC<Props> = () => {
  const { t } = useTranslation('contact');
  const noticeType = useContext(DmcaNoticeTypeContext);
  const isContactInfoRequired = noticeType === DmcaNoticeType.Counter;
  return (
    <FormSection
      heading={
        <FormSectionHeading>
          {t('contact:dmcaForm.submitterInfoBlock.heading')}
        </FormSectionHeading>
      }
    >
      <FormTextInput
        id="email"
        name="email"
        type="email"
        label={t('contact:dmcaForm.submitterInfoBlock.field.email.label')}
        isRequired
      />
      {noticeType === DmcaNoticeType.Representative && (
        <>
          <FormTextInput
            id="copyrightHoldersName"
            name="copyrightHoldersName"
            label={t(
              'contact:dmcaForm.submitterInfoBlock.field.copyrightHoldersName.label'
            )}
            isRequired
          />
          <FormTextInput
            id="authorityForComplaint"
            name="authorityForComplaint"
            label={t(
              'contact:dmcaForm.submitterInfoBlock.field.authorityForComplaint.label'
            )}
            isRequired
          />
        </>
      )}
      <FormTextInput
        id="name"
        name="name"
        label={t('contact:dmcaForm.submitterInfoBlock.field.name.label')}
        isRequired
      />
      <FormTextInput
        id="phone"
        name="phone"
        type="tel"
        label={t('contact:dmcaForm.submitterInfoBlock.field.phone.label')}
        isRequired={isContactInfoRequired}
      />
      <FormTextInput
        id="fax"
        name="fax"
        type="tel"
        label={t('contact:dmcaForm.submitterInfoBlock.field.fax.label')}
      />
      <FormTextInput
        id="street-address"
        name="streetAddress"
        label={t(
          'contact:dmcaForm.submitterInfoBlock.field.streetAddress.label'
        )}
        isRequired={isContactInfoRequired}
      />
      <FormTextInput
        id="apartment"
        name="apartment"
        label={t('contact:dmcaForm.submitterInfoBlock.field.apartment.label')}
      />
      <FormTextInput
        id="city"
        name="city"
        label={t('contact:dmcaForm.submitterInfoBlock.field.city.label')}
        isRequired={isContactInfoRequired}
      />
      <FormTextInput
        id="state"
        name="state"
        label={t('contact:dmcaForm.submitterInfoBlock.field.state.label')}
      />
      <FormTextInput
        id="zip"
        name="zip"
        label={t('contact:dmcaForm.submitterInfoBlock.field.zip.label')}
        isRequired={isContactInfoRequired}
      />
      <CountryDropdown isRequired={isContactInfoRequired} />
    </FormSection>
  );
};

DmcaSubmitterInfoBlock.displayName = 'DmcaSubmitterInfoBlock';

export default DmcaSubmitterInfoBlock;
