import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CONTACT_PAGE_ANCHOR_JUMP_POINTS } from '@wix/da-url';
import ContentBlock from '../ContentBlock';
import ContentBlockHeading from '../ContentBlockHeading';

import s from './IssueResultBlock.scss';

interface Props {
  topicId: string;
}

const IssueResultBlock: React.FC<Props> = ({ topicId }) => {
  const { t } = useTranslation('contact');
  const linksForLocale = labelsToLabelToLinkMap(LINK_LABELS, t);

  return (
    <ContentBlock
      heading={
        <ContentBlockHeading
          id={CONTACT_PAGE_ANCHOR_JUMP_POINTS.ISSUE_RESPONSE}
        >
          {t('contact:resultBlock.heading')}
        </ContentBlockHeading>
      }
      content={
        <div className={s['content']}>
          <Trans
            i18nKey={`contact:resultBlock.${topicId}`}
            components={{
              p: <p />,
              b: <b />,
              i: <i />,
              u: <u />,
              ol: <ol />,
              ul: <ul />,
              li: <li />,
              br: <br />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a />,
              ...linksForLocale,
            }}
          />
        </div>
      }
    />
  );
};
IssueResultBlock.displayName = 'IssueResultBlock';

export default IssueResultBlock;

/** given string labels used in our contact localization strings for links, will return
 *  a map/object where each label is the key pointing to an anchor with a url also in babel.
 *  this lets us remove a lot of repitition and boilerplate while letting content edit urls.
 */
function labelsToLabelToLinkMap(
  labels = [] as string[],
  t: (s: string) => string
): Record<string, React.ReactNode> {
  const labelToLinkMap: Record<string, React.ReactNode> = {};
  for (const label of labels) {
    const url = t(`resultBlock.url.${label}`);
    if (url) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      labelToLinkMap[label] = <a href={url} />;
    }
  }
  return labelToLinkMap;
}

// these are the link labels used in the issue blocks
// they're urls in the locale files are in the form `resultBlock.url.${label}`
// toodoo: wish there was a way to just include the hrefs in the containing locale string...

// todo: to underscores (matching containing block)
// todo: can we use dots in here?
const LINK_LABELS = [
  'login_suspended.url.1',
  'login_change_username.url.1',
  'login_change_username.url.2',
  'login_change_username.url.3',
  'login_deactivate.url.1',
  'login_deactivate.url.2',
  'login_reactivate.url.1',
  'login_reactivate.url.2',
  'buysell_core_purchase_error.url.1',
  'buysell_core_cancel_rebill.url.1',
  'buysell_core_cancel_rebill.url.2',
  'buysell_core_badges.url.1',
  'buysell_pcpcomm_pcp_more_info.url.1',
  'buysell_pcpcomm_comm_more_info.url.1',
  'buysell_pcpcomm_comm_more_info.url.2',
  'buysell_pcpcomm_pg_more_info.url.1',
  'buysell_sellart_prints.url.1',
  'buysell_sellart_prints_pricing.url.1',
  'buysell_sellart_prints_pricing.url.2',
  'buysell_sellart_prints_files.url.1',
  'buysell_sellart_prints_files.url.2',
  'buysell_earnings_find.url.1',
  'buysell_earnings_withdraw.url.1',
  'buysell_earnings_withdraw.url.2',
  'buysell_earnings_withdraw.url.3',
  'buysell_earnings_withdraw.url.4',
  'buysell_earnings_withdraw_error.url.1',
  'buysell_earnings_withdraw_error.url.2',
  'buysell_buyart_unhappy.url.1',
  'buysell_cancelreturn_core.url.1',
  'buysell_cancelreturn_prints.url.1',
  'subscriptions_learn_more.url.1',
  'subscriptions_learn_more.url.2',
  'subscriptions_cancel.url.1',
  'copyright_dmca_holder.url.1',
  'copyright_dmca_holder.url.2',
  'copyright_dmca_representative.url.1',
  'copyright_dmca_representative.url.2',
  'copyright_dmca_other.url.1',
  'copyright_dmca_other.url.2',
  'copyright_protect.url.1',
  'copyright_protect.url.2',
  'copyright_protect.url.3',
  'policies_report_comment_me.url.1',
  'policies_report_comment_me.url.2',
  'policies_report_group.url.1',
  'policies_report_group.url.2',
  'policies_deviations_allowed.url.1',
  'policies_deviations_allowed.url.2',
  'policies_deviations_allowed.url.3',
  'policies_deviations_allowed.url.4',
  'policies_deviations_allowed.url.5',
  'policies_deviations_mature.url.1',
  'policies_deviations_model_release.url.1',
  'policies_groups_rp.url.1',
  'policies_groups_rp.url.2',
  'policies_spam_appeal.url.1',
  'policies_suspensions_appeal.url.1',
  'general_content_submit.url.1',
  'general_content_submit.url.2',
  'general_content_submit.url.3',
  'general_content_submit.url.4',
  'general_account_change_username.url.1',
  'general_content_collect.url.1',
  'general_content_collect.url.2',
  'general_content_delete.url.1',
  'general_browse_search.url.1',
  'general_browse_options.url.1',
  'general_customization_profile.url.1',
  'general_customization_profile.url.2',
  'general_customization_profile.url.3',
  'general_account_reactivate.url.1',
  'general_account_reactivate.url.2',
  'general_account_suspension_appeal.url.1',
  'general_account_birthdate.url.1',
  'general_account_birthdate.url.2',
  'general_account_deactivate.url.1',
  'general_account_deactivate.url.2',
  'troubleshooting_browser_support.url.1',
  'troubleshooting_comments.url.1',
  'troubleshooting_submit.url.1',
  'troubleshooting_submit.url.2',
  'troubleshooting_loading.url.1',
  'troubleshooting_avatar.url.1',
  'troubleshooting_avatar.url.2',
  'troubleshooting_mobile_ios.url.1',
  'troubleshooting_mobile_ios.url.2',
  'troubleshooting_mobile_android.url.1',
  'troubleshooting_mobile_android.url.2',
  'general_feedback.url.1',
];
