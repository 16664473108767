import { SagaIterator } from 'redux-saga';
import { call, select, all, takeLatest } from 'redux-saga/effects';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import {
  PapiDacheckoutRemoveCoupon,
  PapiRequestDacheckoutRemoveCoupon,
} from '@wix/da-papi-types';
import i18next from 'i18next';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { XhrResponse, isApiError, requestPuppy } from '@wix/da-http-client';
import { submitCouponCode, removeCouponCode } from '../actions/checkout';
import { getOrderInfo, getGiftUser } from '../selectors/checkout';
import { getReferer } from '../selectors/common';

function* submitCouponCodeSaga(action: ReturnType<typeof submitCouponCode>) {
  if (!action.payload) {
    return;
  }
  const { couponCode } = action.payload;
  const referer = yield select(getReferer);
  const params = new URLSearchParams(history.location.search);
  params.set('coupon', couponCode);
  if (referer) {
    params.set('referer', referer);
  }
  history.replace({
    ...history.location,
    search: params.toString(),
  });
  window.location.reload();
}

function* removeCouponCodeSaga(action: ReturnType<typeof removeCouponCode>) {
  const order = yield select(getOrderInfo);
  const giftFor = yield select(getGiftUser);

  const data: PapiRequestDacheckoutRemoveCoupon = {
    subproductid: order?.subproductId,
    recipientid: giftFor?.userId,
  };

  const response: XhrResponse<PapiDacheckoutRemoveCoupon> = yield call(
    requestPuppy,
    {
      method: 'post',
      url: 'remove_coupon',
      data,
    },
    undefined,
    'dacheckout'
  );

  if (!response || isApiError(response) || !response.success) {
    yield putErrorNotification(i18next.t('common.error.removeCoupon'));
    return;
  }
  const params = new URLSearchParams(history.location.search);
  const referer = yield select(getReferer);
  if (referer) {
    params.set('referer', referer);
  }
  params.delete('coupon');
  history.replace({
    ...history.location,
    search: params.toString(),
  });
  window.location.reload();
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(submitCouponCode, submitCouponCodeSaga),
    takeLatest(removeCouponCode, removeCouponCodeSaga),
  ]);
}
