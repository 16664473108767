import React, { useContext } from 'react';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import { getDmcaFormImageUploads } from '../../../../redux/contactUs/selectors';
import {
  initializeDmcaImageUploadEntry,
  removeDmcaImageUploadEntry,
  resetDmcaImageUpload,
  updateDmcaImageUploadEntry,
  uploadDmcaImage,
} from '../../../../redux/contactUs/actions';
import { DmcaNoticeType } from '../../../../redux/contactUs/types';
import { fileToBase64 } from '../../utils/utils';
import FileUploadPreview from '../../partials/FileUploadPreview';
import FileUploadPrompt from '../../partials/FileUploadPrompt';
import FormSectionSeparator from '../../partials/FormSectionSeparator';
import { DmcaNoticeTypeContext } from '../../DmcaFormBlock/noticeTypeContext';

import s from './MultiFileUploadField.scss';

interface Props {}

const MultiFileUploadField: React.FC<Props> = () => {
  const { t } = useTranslation('contact');
  const noticeType = useContext(DmcaNoticeTypeContext);
  const imageUploads = useSelector(getDmcaFormImageUploads);
  const dispatch = useDispatch();

  return (
    <div className={s['root']}>
      {Object.values(imageUploads).map((imageUploadEntry, index) => (
        <React.Fragment key={imageUploadEntry.entryUuid}>
          <TextInput
            id={`infringing-link-${index}`}
            label={t(
              noticeType === DmcaNoticeType.Counter
                ? 'contact:dmcaForm.uploadBlock.infringingDeviationLink.label.counter'
                : 'contact:dmcaForm.uploadBlock.infringingDeviationLink.label'
            )}
            value={imageUploadEntry.infringingUrl}
            onChange={e =>
              dispatch(
                updateDmcaImageUploadEntry(imageUploadEntry.entryUuid, {
                  infringingUrl: e.target.value,
                })
              )
            }
            isRequired
            noBottomSpacing
          />

          <TextInput
            id={`title-${index}`}
            label={t('contact:dmcaForm.uploadBlock.workTitle.label')}
            value={imageUploadEntry.title}
            onChange={e =>
              dispatch(
                updateDmcaImageUploadEntry(imageUploadEntry.entryUuid, {
                  title: e.target.value,
                })
              )
            }
            isRequired
            noBottomSpacing
          />

          {noticeType !== DmcaNoticeType.Counter && (
            <div className={s['original-url-or-upload']}>
              <TextInput
                id={`original-link-${index}`}
                label={t('contact:dmcaForm.uploadBlock.originalWorkLink.label')}
                onChange={e =>
                  dispatch(
                    updateDmcaImageUploadEntry(imageUploadEntry.entryUuid, {
                      originalUrl: e.target.value,
                    })
                  )
                }
                value={imageUploadEntry.originalUrl}
                isRequired={!imageUploadEntry.uploadResult?.url}
                noBottomSpacing
              />

              <div className={s['or-text']}>
                {t('contact:dmcaForm.uploadBlock.orSeparator')}
              </div>

              <div className={s['label-and-remove-button']}>
                <label>
                  <input
                    className={s['hidden-input']}
                    onClick={e => {
                      // Clearing the input's value as well to allow us to retry the same image
                      (e.target as HTMLInputElement).value = '';
                      dispatch(
                        resetDmcaImageUpload(imageUploadEntry.entryUuid)
                      );
                    }}
                    onChange={async e => {
                      if (e.target.files && e.target.files.length > 0) {
                        const newFile = e.target.files?.[0];

                        dispatch(
                          uploadDmcaImage(imageUploadEntry.entryUuid, {
                            fileObject: newFile,
                            fileBlob: await fileToBase64(newFile),
                          })
                        );
                      }
                    }}
                    type="file"
                    name="files"
                    required={!imageUploadEntry.originalUrl}
                  />

                  {imageUploadEntry.previewDataUrl === undefined && (
                    <FileUploadPrompt />
                  )}
                  {imageUploadEntry.previewDataUrl && (
                    <FileUploadPreview uploadInformation={imageUploadEntry} />
                  )}
                </label>
                {Object.keys(imageUploads).length > 1 && (
                  <SolidButton
                    variant="destructive-red"
                    size="medium"
                    type="button"
                    onClick={() =>
                      dispatch(
                        removeDmcaImageUploadEntry(imageUploadEntry.entryUuid)
                      )
                    }
                  >
                    {t('dmcaForm.uploadBlock.removeDeviationButton.label')}
                  </SolidButton>
                )}
              </div>
            </div>
          )}

          {index !== Object.keys(imageUploads).length - 1 && (
            <FormSectionSeparator />
          )}
        </React.Fragment>
      ))}
      <SolidButton
        variant="approval-green"
        size="medium"
        type="button"
        // @ts-ignore
        onClick={() => dispatch(initializeDmcaImageUploadEntry(uuid()))}
      >
        {t('dmcaForm.uploadBlock.addDeviationButton.label')}
      </SolidButton>
    </div>
  );
};

MultiFileUploadField.displayName = 'MultiFileUploadField';

export default MultiFileUploadField;
