import React from 'react';
import ProgressBubble, { ProgressBubbleProps } from '../ProgressBubble';
import s from './StepperContentStage.scss';

interface Props {
  bubbleValue: ProgressBubbleProps['value'];

  /** Expects a single element. */
  content: React.ReactNode;

  /** Displayed directly below the content. */
  footnote?: React.ReactNode;
}

const StepperContentStage = React.forwardRef<HTMLDivElement, Props>(
  ({ bubbleValue, content, footnote }, ref) => {
    return (
      <div className={s['root']} ref={ref}>
        <ProgressBubble value={bubbleValue} />
        {content}
        {footnote && <div className={s['footnote']}>{footnote}</div>}
      </div>
    );
  }
);

StepperContentStage.displayName = 'StepperContentStage';

export default StepperContentStage;
