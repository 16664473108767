import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CONTACT_PAGE_ANCHOR_JUMP_POINTS } from '@wix/da-url';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton/SolidButton';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitContactForm,
  resetReduxFormValues,
} from '../../../redux/contactUs/actions';
import {
  ContactUsFormValues,
  FormSubmissionStatus,
  MenuTreeNode,
} from '../../../redux/contactUs/types';
import { getFormSubmissionStatus } from '../../../redux/contactUs/selectors';
import FormTextArea from '../partials/FormTextArea';
import ContentBlock from '../partials/ContentBlock';
import ContentBlockHeading from '../partials/ContentBlockHeading';
import FormTextInput from '../partials/FormTextInput';
import FileUploadField from '../formFields/FileUploadField';
import FormSection from '../partials/FormSection';
import FormSectionHeading from '../partials/FormSectionHeading';
import FormSectionDescription from '../partials/FormSectionDescription';
import s from './ContactFormBlock.scss';

interface Props {
  selectedTopics: MenuTreeNode[];
  formRefCallback?: React.RefCallback<HTMLFormElement>;
}

const ContactFormBlock: React.FC<Props> = ({
  selectedTopics,
  formRefCallback,
}) => {
  const { t } = useTranslation('contact');
  const formContext = useForm<ContactUsFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      issueDescription: '',
    },
  });
  const formSubmissionStatus = useSelector(getFormSubmissionStatus);
  const [isFormRevealed, setIsFormRevealed] = useState(false);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (data: ContactUsFormValues) => {
      dispatch(submitContactForm(data, selectedTopics));
    },
    [dispatch, selectedTopics]
  );

  // reset the form after successful submission
  useEffect(() => {
    if (formSubmissionStatus === FormSubmissionStatus.Success) {
      dispatch(resetReduxFormValues());
      formContext.reset();
    }
  }, [dispatch, formSubmissionStatus, formContext]);

  return (
    <ContentBlock
      heading={
        <ContentBlockHeading id={CONTACT_PAGE_ANCHOR_JUMP_POINTS.FORM}>
          Contact Us
        </ContentBlockHeading>
      }
      content={
        <Section className={s['content']}>
          {!isFormRevealed && (
            <TextButton
              variant="approval-green"
              size="small"
              onClick={() => setIsFormRevealed(true)}
            >
              {t('contact:contactForm.gatekeepButton.label')}
            </TextButton>
          )}
          {isFormRevealed && (
            <FormProvider {...formContext}>
              <form
                className={s['form']}
                onSubmit={formContext.handleSubmit(handleSubmit)}
                ref={formRefCallback}
              >
                <FormSection
                  heading={
                    <FormSectionHeading>
                      {t('contact:contactForm.heading')}
                    </FormSectionHeading>
                  }
                  description={
                    <FormSectionDescription>
                      {t('contact:contactForm.description')}
                    </FormSectionDescription>
                  }
                >
                  <div className={s['form-fields']}>
                    <FormTextInput
                      id="title"
                      name="title"
                      label={t('contact:contactForm.field.title.label')}
                      placeholder={t(
                        'contact:contactForm.field.title.placeholder'
                      )}
                      minLength={10}
                      isRequired
                    />

                    <FormTextArea
                      id="issue-description"
                      name="issueDescription"
                      label={t(
                        'contact:contactForm.field.issueDescription.label'
                      )}
                      placeholder={t(
                        'contact:contactForm.field.issueDescription.placeholder'
                      )}
                      minLength={10}
                      isRequired
                    />

                    <FileUploadField />
                  </div>
                </FormSection>
                <div className={s['submit-and-afterword']}>
                  <SolidButton
                    variant="approval-green"
                    size="small"
                    type="submit"
                    disabled={
                      formSubmissionStatus === FormSubmissionStatus.InProgress
                    }
                    loading={
                      formSubmissionStatus === FormSubmissionStatus.InProgress
                    }
                  >
                    {t('contact:contactForm.submit.label')}
                  </SolidButton>
                  {formSubmissionStatus === FormSubmissionStatus.Success && (
                    <p className={s['afterword']}>
                      {t('contact:contactForm.submit.afterword.success')}
                    </p>
                  )}
                </div>
              </form>
            </FormProvider>
          )}
        </Section>
      }
    />
  );
};
ContactFormBlock.displayName = 'ContactFormBlock';

export default ContactFormBlock;
