import { createAction } from 'redux-actions';
import { reduxKey } from './constants';
import {
  FormFileValue,
  ContactUsFormValues,
  DmcaFormValues,
  DmcaNoticeType,
  DmcaFormImageUpload,
  MenuTreeNode,
} from './types';

export const initContactUsPage = createAction(
  `${reduxKey}.INIT_CONTACT_US_PAGE`,
  (
    rawMenuData,
    initialMenuItemId: string | undefined,
    countries: { countryId: number; countryName: string }[]
  ) => ({
    rawMenuData,
    initialMenuItemId,
    countries,
  })
);

/**
 * This resets images uploads and the form submission status
 */
export const resetReduxFormValues = createAction(
  `${reduxKey}.RESET_REDUX_FORM_VALUES`,
  () => ({})
);

export const submitDmcaForm = createAction(
  `${reduxKey}.SUBMIT_DMCA_FORM`,
  (
    data: DmcaFormValues,
    noticeType: DmcaNoticeType,
    selectedTopics: MenuTreeNode[]
  ) => ({ data, noticeType, selectedTopics })
);

export const submitContactForm = createAction(
  `${reduxKey}.SUBMIT_CONTACT_FORM`,
  (data: ContactUsFormValues, selectedTopics: MenuTreeNode[]) => ({
    data,
    selectedTopics,
  })
);

export const submitDmcaFormSuccess = createAction(
  `${reduxKey}.SUBMIT_DMCA_FORM_SUCCESS`,
  () => ({})
);

export const submitDmcaFormFailure = createAction(
  `${reduxKey}.SUBMIT_DMCA_FORM_FAILURE`,
  () => ({})
);

export const submitContactFormSuccess = createAction(
  `${reduxKey}.SUBMIT_CONTACT_FORM_SUCCESS`,
  () => ({})
);

export const submitContactFormFailure = createAction(
  `${reduxKey}.SUBMIT_CONTACT_FORM_FAILURE`,
  () => ({})
);

export const initializeDmcaImageUploadEntry = createAction(
  `${reduxKey}.INITIALIZE_DMCA_IMAGE_UPLOAD_ENTRY`,
  (entryUuid: string) => ({
    entryUuid,
  })
);

/**
 * We use this to update upload adjacent text inputs (e.g. infringing url, original deviation url, work title, etc)
 *
 * Other parts of the upload entry state are updated with dedicated actions.
 */
export const updateDmcaImageUploadEntry = createAction(
  `${reduxKey}.UPDATE_DMCA_IMAGE_UPLOAD_ENTRY`,
  (
    entryUuid: string,
    data: Partial<
      Omit<
        DmcaFormImageUpload,
        'entryUuid' | 'uploadResult' | 'previewDataUrl' | 'fileName'
      >
    >
  ) => ({ entryUuid, data })
);

export const removeDmcaImageUploadEntry = createAction(
  `${reduxKey}.REMOVE_DMCA_IMAGE_UPLOAD_ENTRY`,
  (entryUuid: string) => ({ entryUuid })
);

export const uploadDmcaImage = createAction(
  `${reduxKey}.UPLOAD_DMCA_IMAGE`,
  (entryUuid: string, file: Required<FormFileValue>) => ({
    entryUuid,
    file,
  })
);

export const resetDmcaImageUpload = createAction(
  `${reduxKey}.RESET_DMCA_IMAGE_UPLOAD`,
  (entryUuid: string) => ({
    entryUuid,
  })
);

export const uploadDmcaImageSuccess = createAction(
  `${reduxKey}.UPLOAD_DMCA_IMAGE_SUCCESS`,
  (entryUuid: string, responseContent: { name: string; url: string }) => ({
    entryUuid,
    name: responseContent.name,
    url: responseContent.url,
  })
);

export const uploadDmcaImageFailure = createAction(
  `${reduxKey}.UPLOAD_DMCA_IMAGE_FAILURE`,
  (entryUuid: string) => ({
    entryUuid,
  })
);

export const resetContactImageUpload = createAction(
  `${reduxKey}.RESET_CONTACT_IMAGE_UPLOAD`,
  () => ({})
);

export const uploadContactImage = createAction(
  `${reduxKey}.UPLOAD_CONTACT_IMAGE`,
  (file: Required<FormFileValue>) => ({
    file,
  })
);

export const uploadContactImageSuccess = createAction(
  `${reduxKey}.UPLOAD_CONTACT_IMAGE_SUCCESS`,
  (responseContent: { name: string; url: string }) => ({
    name: responseContent.name,
    url: responseContent.url,
  })
);

export const uploadContactImageFailure = createAction(
  `${reduxKey}.UPLOAD_CONTACT_IMAGE_FAILURE`,
  () => ({})
);
