import { handleActions } from 'redux-actions';
import { AppState } from '../types/store';

import {
  addAddressToList,
  removeAddressFromList,
  removeCardFromList,
  removeCoreSubscription,
  removeMemberSubscription,
} from '../actions/billing';

export default handleActions<AppState['account'], any>(
  {
    [removeAddressFromList.toString()]: (
      state,
      action: ReturnType<typeof removeAddressFromList>
    ) => {
      return {
        ...state,
        addresses: state?.addresses?.filter(
          address => address.addressId !== action.payload.addressId
        ),
      };
    },
    [addAddressToList.toString()]: (
      state,
      action: ReturnType<typeof addAddressToList>
    ) => {
      return {
        ...state,
        addresses: [...(state?.addresses ?? []), action.payload.address],
      };
    },
    [removeCardFromList.toString()]: (
      state,
      action: ReturnType<typeof removeCardFromList>
    ) => {
      return {
        ...state,
        cards: state?.cards?.filter(
          card => card.cardStoredCardid !== action.payload.cardId
        ),
      };
    },
    [removeCoreSubscription.toString()]: (
      state,
      action: ReturnType<typeof removeCoreSubscription>
    ) => {
      if (!state || !state.coreSubscription) {
        return state;
      }
      return {
        ...state,
        coreSubscription: {
          ...state.coreSubscription,
          isAutorenewalOn: false,
        },
      };
    },
    [removeMemberSubscription.toString()]: (
      state,
      action: ReturnType<typeof removeMemberSubscription>
    ) => {
      if (!state || !state.subscriptions) {
        return state;
      }
      return {
        ...state,
        subscriptions: state.subscriptions.map(subscription =>
          subscription.orderId === action.payload.orderId
            ? { ...subscription, isActive: false }
            : subscription
        ),
      };
    },
  },
  {}
);
