import React from 'react';
import AttachIcon from '@wix/da-ds/pkg/Icons/16x16/Attach';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './FileUploadPrompt.scss';

interface Props {}

const FileUploadPrompt: React.FC<Props> = () => {
  return (
    <span className={s['root']}>
      <AttachIcon size={IconSize.SMALLER} />{' '}
      <span className={s['attach-text']}>attach a file</span>{' '}
      <span className={s['size-hint']}>(max file size 5MB)</span>
    </span>
  );
};

FileUploadPrompt.displayName = 'FileUploadPrompt';

export default FileUploadPrompt;
