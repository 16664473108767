import { Reducer } from 'redux';
import { reducers as sharedReducers } from '@wix/da-shared-react/pkg/redux/shared';
import { AppState } from '../types/store';
import checkoutPage from '../reducers/checkoutPage';
import account from '../reducers/billing';
import contactUs from '../redux/contactUs/reducer';
import packagePicker from '../redux/packagePicker/reducer';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, _action) =>
    state;

// This type makes sure we do not forget to add a reducer for any slice
// of the store nor can we add extras for non-existing slices.
// Needed because monday (thursday) mornings exist.

type AppStateKeys = keyof AppState;
type AppStateReducersMapObject = { [k in AppStateKeys]: Reducer<any> };

const reducers: AppStateReducersMapObject = {
  ...headReducers,
  checkoutPage,
  orderConfirmation: buildFakeReducer(null),
  aboutPage: buildFakeReducer(null),
  staffByPosition: buildFakeReducer(null),
  salesPage: buildFakeReducer(null),
  foruserid: buildFakeReducer(null),
  couponCode: buildFakeReducer(null),
  outgoingPage: buildFakeReducer(null),
  account,
  ...packagePicker,
  ...contactUs,
  ...sharedReducers,
};

export default reducers;
