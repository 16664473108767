import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuTreeNode } from '../../../../redux/contactUs/types';
import ContentBlock from '../ContentBlock';
import ContentBlockHeading from '../ContentBlockHeading';
import IssueCategoryCard from '../IssueCategoryCard';
import s from './IssueCategoriesBlock.scss';

export interface Props {
  categories: MenuTreeNode[];
  selectedCategory?: MenuTreeNode;
  onSelect: (id: MenuTreeNode) => void;
}

const IssueCategoriesBlock: React.FC<Props> = ({
  categories,
  selectedCategory,
  onSelect,
}) => {
  const { t } = useTranslation('contact');
  return (
    <ContentBlock
      heading={
        <ContentBlockHeading className={s['heading']}>
          {t('contact:categoriesBlock.heading')}
        </ContentBlockHeading>
      }
      content={
        <div className={s['content']}>
          {categories.map(category => (
            <IssueCategoryCard
              key={category.id}
              title={t(`contact:categoriesBlock.card.${category.id}.heading`)}
              subtext={t(
                `contact:categoriesBlock.card.${category.id}.description`
              )}
              value={category.id}
              checked={selectedCategory?.id === category.id}
              onChange={() => onSelect(category)}
            />
          ))}
        </div>
      }
    />
  );
};
IssueCategoriesBlock.displayName = 'IssueCategoriesBlock';

export default IssueCategoriesBlock;
