import { handleActions } from 'redux-actions';
import { AppState } from '../types/store';
import {
  changePaymentMethod,
  giftIsAnonChanged,
  noteChanged,
  setErrorCode,
  approvePaypalOrder,
  approvePaypalOrderFailed,
  setStripeErrorMessage,
  setIsWalletsNotSupported,
} from '../actions/checkout';

export default handleActions<AppState['checkoutPage'], any>(
  {
    [`${changePaymentMethod}`]: (state, action) => ({
      ...(state || {}),
      activePaymentMethod: action.payload.method,
    }),
    [noteChanged.toString()]: (
      state,
      action: ReturnType<typeof noteChanged>
    ) => {
      return {
        ...state,
        form: {
          ...state?.form,
          note: action.payload.note,
        },
      };
    },
    [giftIsAnonChanged.toString()]: (
      state,
      action: ReturnType<typeof giftIsAnonChanged>
    ) => {
      return {
        ...state,
        form: {
          ...state?.form,
          giftIsAnon: action.payload.anon,
        },
      };
    },
    [setErrorCode.toString()]: (
      state,
      action: ReturnType<typeof setErrorCode>
    ) => {
      return {
        ...state,
        errorCode: action.payload.code,
      };
    },
    [approvePaypalOrder.toString()]: (
      state,
      action: ReturnType<typeof approvePaypalOrder>
    ) => {
      return {
        ...state,
        isApprovingPaypal: true,
      };
    },
    [approvePaypalOrderFailed.toString()]: (
      state,
      action: ReturnType<typeof approvePaypalOrder>
    ) => {
      return {
        ...state,
        isApprovingPaypal: false,
      };
    },
    [setStripeErrorMessage.toString()]: (
      state,
      action: ReturnType<typeof setStripeErrorMessage>
    ) => ({
      ...state,
      stripeErrorMessage: action.payload.errorMessage,
    }),
    [setIsWalletsNotSupported.toString()]: (
      state,
      action: ReturnType<typeof setIsWalletsNotSupported>
    ) => ({
      ...state,
      isWalletsNotSupported: true,
    }),
  },
  {}
);
