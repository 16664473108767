import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import s from './ContentBlock.scss';

interface Props {
  heading: React.ReactNode;
  content: React.ReactNode;
  className?: string;
}

const ContentBlock: React.FC<Props> = ({ heading, content, className }) => {
  return (
    <Section className={c(s['root'], className)}>
      {heading}
      {content}
    </Section>
  );
};
ContentBlock.displayName = 'ContentBlock';

export default ContentBlock;
