import type {
  StripeExpressCheckoutElementClickEvent,
} from '@stripe/stripe-js';
import { createAction } from 'redux-actions';
import { PaymentMethod } from '../types';

export const changePaymentMethod = createAction(
  'CHECKOUT.CHANGE_PAYMENT_METHOD',
  (method: PaymentMethod) => ({ method })
);

export const noteChanged = createAction(
  'CHECKOUT.NOTE_CHANGED',
  (note: string) => ({ note })
);

export const giftIsAnonChanged = createAction(
  'CHECKOUT.GIFT_IS_ANON_CHANGED',
  (anon: boolean) => ({ anon })
);

export const approvePaypalOrder = createAction(
  'CHECKOUT.PAYPAL.APPROVE_ORDER',
  (data: any, actions: any) => ({ data, actions })
);

export const approvePaypalSubscription = createAction(
  'CHECKOUT.PAYPAL.APPROVE_SUBSCRIPTION',
  (ordergroupId: number) => ({ ordergroupId })
);

export const approvePaypalOrderFailed = createAction(
  'CHECKOUT.PAYPAL.APPROVE_ORDER_FAILED',
  () => ({})
);

export const cancelPaypalOrder = createAction(
  'CHECKOUT.PAYPAL.CANCEL_ORDER',
  (ordergroupId: number, isSubscription: boolean) => ({
    ordergroupId,
    isSubscription,
  })
);

export const setErrorCode = createAction(
  'CHECKOUT.SET_ERROR_CODE',
  (code: string) => ({ code })
);

export const submitCouponCode = createAction(
  'CHECKOUT.COUPON_CODE.SUBMIT',
  (couponCode: string) => ({ couponCode })
);

export const removeCouponCode = createAction(
  'CHECKOUT.COUPON_CODE.REMOVE',
  (couponCode: string) => ({ couponCode })
);

export const stripeExpressButtonClicked = createAction(
  'CHECKOUT.STRIPE.CLICK',
  (event: StripeExpressCheckoutElementClickEvent) => ({ event })
);

export const setStripeErrorMessage = createAction(
  'CHECKOUT.STRIPE.SET_ERROR_MESSAGE',
  (errorMessage: string) => ({ errorMessage })
);

export const setIsWalletsNotSupported = createAction(
  'CHECKOUT.SET_IS_WALLETS_UNSUPPORTED',
  () => ({})
);
