import React from 'react';

const PaperAirplaneIllustration: React.FC<
  Partial<CSSStyleDeclaration>
> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 99 115"
      width="99px"
      height="115px"
    >
      <path
        fill="url(#paper-airplane-a)"
        d="M82 17.658 69.673 83.211l-17.299-5.408-14.059 17.855-11.492-25.842L1 61.744l81-44.086Z"
      />
      <path
        fill="url(#paper-airplane-b)"
        d="M53.813 76.22 38.425 95.965l-.406-23.34 15.794 3.595Z"
      />
      <path
        fill="url(#paper-airplane-c)"
        d="m83 16.658-2.602 10.39h-.013l-10.997 55.61-30.947-9.959 33.191-42.276-44.163 39.511L0 61.658 82.038 17.17l.403-.512-.067.333.626-.333Z"
      />
      <path
        fill="url(#paper-airplane-d)"
        d="M16.49 92.148 14 98.658l-2.49-6.51L5 89.658l6.51-2.49 2.49-6.51 2.49 6.51 6.51 2.49-6.51 2.49Z"
      />
      <path
        fill="url(#paper-airplane-e)"
        d="M29.148 106.806 27 110.658l-2.148-3.852L21 104.658l3.852-2.148L27 98.658l2.148 3.852L33 104.658l-3.852 2.148Z"
      />
      <path
        fill="url(#paper-airplane-f)"
        d="m91 8.301-5.657 3.014 3.014-5.657L85.343.001 91 3.015 96.657.001l-3.014 5.657 3.014 5.657L91 8.301Z"
      />
      <path
        fill="url(#paper-airplane-g)"
        d="m97 15.658 1.414.586.586 1.414-.586 1.414-1.414.586-1.414-.586L95 17.658l.586-1.414L97 15.658Z"
      />
      <path
        fill="url(#paper-airplane-h)"
        d="m10 108.658 2.121.879.879 2.121-.879 2.121-2.121.879-2.121-.879L7 111.658l.879-2.121 2.121-.879Z"
      />
      <defs>
        <linearGradient
          id="paper-airplane-a"
          x1="41.5"
          x2="41.5"
          y1="17.658"
          y2="95.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".2" stopColor="#E1E1E1" />
          <stop offset=".4" stopColor="#C3C3C3" />
          <stop offset=".6" stopColor="#A5A5A5" />
          <stop offset=".8" stopColor="#878787" />
          <stop offset="1" stopColor="#696969" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-b"
          x1="44.014"
          x2="47.989"
          y1="68.859"
          y2="82.421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A9EA1" stopOpacity="0" />
          <stop offset=".2" stopColor="#9A9EA1" stopOpacity=".2" />
          <stop offset=".4" stopColor="#9A9EA1" stopOpacity=".4" />
          <stop offset=".6" stopColor="#9A9EA1" stopOpacity=".6" />
          <stop offset=".8" stopColor="#9A9EA1" stopOpacity=".8" />
          <stop offset="1" stopColor="#9A9EA1" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-c"
          x1="45.97"
          x2="61.995"
          y1="35.565"
          y2="73.59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".2" stopColor="#EBECEC" />
          <stop offset=".4" stopColor="#D7D8D9" />
          <stop offset=".6" stopColor="#C2C5C7" />
          <stop offset=".8" stopColor="#AEB1B4" />
          <stop offset="1" stopColor="#9A9EA1" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-d"
          x1="14"
          x2="7.503"
          y1="80.658"
          y2="96.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D38F" stopOpacity="0" />
          <stop offset=".2" stopColor="#00D38F" stopOpacity=".2" />
          <stop offset=".4" stopColor="#00D38F" stopOpacity=".4" />
          <stop offset=".6" stopColor="#00D38F" stopOpacity=".6" />
          <stop offset=".8" stopColor="#00D38F" stopOpacity=".8" />
          <stop offset="1" stopColor="#00D38F" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-e"
          x1="27"
          x2="27"
          y1="98.658"
          y2="110.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A9EA1" stopOpacity="0" />
          <stop offset=".2" stopColor="#9A9EA1" stopOpacity=".2" />
          <stop offset=".4" stopColor="#9A9EA1" stopOpacity=".4" />
          <stop offset=".6" stopColor="#9A9EA1" stopOpacity=".6" />
          <stop offset=".8" stopColor="#9A9EA1" stopOpacity=".8" />
          <stop offset="1" stopColor="#9A9EA1" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-f"
          x1="74.007"
          x2="94.564"
          y1="-6.863"
          y2="8.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D38F" stopOpacity="0" />
          <stop offset=".968" stopColor="#00D38F" stopOpacity=".968" />
          <stop offset="1" stopColor="#00D38F" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-g"
          x1="94.553"
          x2="99"
          y1="13.585"
          y2="19.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#161A1F" stopOpacity="0" />
          <stop offset="1" stopColor="#161A1F" />
        </linearGradient>
        <linearGradient
          id="paper-airplane-h"
          x1="6.329"
          x2="13"
          y1="105.548"
          y2="115.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#292F34" stopOpacity="0" />
          <stop offset=".2" stopColor="#292F34" stopOpacity=".2" />
          <stop offset=".4" stopColor="#292F34" stopOpacity=".4" />
          <stop offset=".6" stopColor="#292F34" stopOpacity=".6" />
          <stop offset=".8" stopColor="#292F34" stopOpacity=".8" />
          <stop offset="1" stopColor="#292F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaperAirplaneIllustration;
