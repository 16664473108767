import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name'> {}

const AcceptsPossibleAccountTerminationCheckbox: React.FC<Props> = props => (
  <FormCheckbox
    id="acceptsPossibleTermination"
    name="acceptsPossibleTermination"
    {...props}
  />
);

AcceptsPossibleAccountTerminationCheckbox.displayName =
  'AcceptsPossibleAccountTerminationCheckbox';

export default AcceptsPossibleAccountTerminationCheckbox;
