/**
 * Why a tree structure and why all this roundabout intermediary tree stuff?
 *
 * Since we use this on a contact page with an arbitrary number of dropdowns
 * all with an arbitrary number of options, performance could be a concern once our
 * customer facing folks get excited.
 *
 * Having the intermediary tree lets us reduce the complexity of building the tree
 * to around O(2n) instead of O(n^n).
 *
 * The first time round the loop is the intermediary step. It links parents to their children since,
 * when we receive the data, only children are linked to their parents. Our UI is top down, so the original state
 * isn't great for that purpose.
 *
 * The second loop is us mapping that intermediary state to the final tree.
 *
 * ---
 *
 * I've already said this up there ^, but another reason for a tree structure is that it maps better to
 * our final UI. So this work is hopefully feeding two birds with one scone.
 */

/**
 * A tree node that isn't quite ready to be included in the final tree structure.
 */
interface IntermediaryTreeNode<InternalDataType> {
  id: string;
  parentId?: string | null;
  isRootNode: boolean;
  isLeafNode: boolean;
  data: InternalDataType;
  childrenIds: string[];
}

/**
 * A node in our tree.
 *
 * Notes:
 * - Does not store a reference to its parent node
 * - Can have any number of children
 */
export interface TreeNode<InternalDataType> {
  /** The id of this node. */
  id: string;

  /** Arbitrary data that was provided by you. */
  data: InternalDataType;

  /** Any number of nodes that are direct children of this node. */
  children: TreeNode<InternalDataType>[];

  /** True if this node's `parent` field is empty. This is only syntatic sugar. */
  isRootNode: boolean;

  /** `true` if `children` is empty. This is only syntatic sugar. */
  isLeafNode: boolean;
}

/**
 * An with two required fields: `id` and `parentId`
 *
 * These two fields are referenced to string the tree data structure together.
 */
export type RawDataEntry<InternalDataType> = {
  /** The id of this entry */
  id: string;
  /** The id of the entry that's to sit above it in the final tree */
  parentId?: string | null;
} & {
  [Property in keyof InternalDataType as Exclude<
    Property,
    'id' | 'parentId'
  >]: InternalDataType[Property];
};

/**
 * A tree structure that can have multiple root nodes.
 *
 * Note:
 * - Each node can only have one parent.
 */
export interface MultiOriginTree<InternalDataType> {
  rootNodes: TreeNode<InternalDataType>[];
}

/**
 * Takes our raw data and converts it into a format that's a bit easier to work with when generating a tree structure.
 */
export function generateIntermediaryTree<InternalDataType>(
  rawData: RawDataEntry<InternalDataType>[],
  getNodeData: (
    rawDataEntry: RawDataEntry<InternalDataType>
  ) => InternalDataType
) {
  const nodes: {
    [nodeId: string]: IntermediaryTreeNode<InternalDataType>;
  } = {};

  rawData.forEach(entry => {
    // If we've already seen this entry's id before (e.g. was referenced in a parentId), make sure we populate the remaining node information
    if (nodes.hasOwnProperty(entry.id)) {
      nodes[entry.id].isRootNode =
        entry.parentId === null || entry.parentId === undefined;
      nodes[entry.id].parentId = entry.parentId;
      nodes[entry.id].data = getNodeData(entry);

      // Otherwise we start the node from scratch
    } else {
      nodes[entry.id] = {
        id: entry.id,
        parentId: entry.parentId,
        isRootNode: entry.parentId === null || entry.parentId === undefined,
        data: getNodeData(entry),
        isLeafNode: true,
        childrenIds: [],
      };
    }

    // If the entry's parentId is already parsed into our tree, we'll update that existing node with the new child
    if (entry.parentId && nodes.hasOwnProperty(entry.parentId)) {
      nodes[entry.parentId].childrenIds.push(entry.id);
      nodes[entry.parentId].isLeafNode = false;

      // Otherwise, we'll create a new tree node and populate the data that we can
    } else if (entry.parentId) {
      nodes[entry.parentId] = {
        id: entry.parentId,
        parentId: null,
        isRootNode: true,
        isLeafNode: false,
        data: {} as InternalDataType,
        childrenIds: [entry.id],
      };
    }
  });

  return nodes;
}

/** Takes an {@link IntermediaryTreeNode} and maps it to the final {@link TreeNode} */
export function mapIntermediaryTreeNodeToFinalTreeNode<InternalDataType>(
  nodes: { [entryId: string]: IntermediaryTreeNode<InternalDataType> },
  node: IntermediaryTreeNode<InternalDataType>
): TreeNode<InternalDataType> {
  return {
    id: node.id,
    isRootNode: node.isRootNode,
    isLeafNode: node.isLeafNode,
    data: node.data as InternalDataType,
    children: node.childrenIds.map(childrenId =>
      mapIntermediaryTreeNodeToFinalTreeNode(nodes, nodes[childrenId])
    ),
  };
}

/**
 * Takes our root {@link IntermediaryTreeNode}s and steps through their children to generate the
 * final tree structure.
 */
export function generateFinalTree<InternalDataType>(nodes: {
  [entryId: string]: IntermediaryTreeNode<InternalDataType>;
}): MultiOriginTree<InternalDataType> {
  return {
    rootNodes: Object.values(nodes)
      .filter(node => node.isRootNode)
      .map(
        (node): TreeNode<InternalDataType> =>
          mapIntermediaryTreeNodeToFinalTreeNode(nodes, node)
      ),
  };
}

/**
 * Generates a {@link MultiOriginTree} from your supplied array of {@link RawDataEntry}'s.
 */
export function generateTree<InternalDataType>(
  /**
   * Your raw data. This will be any series of objects that all contain the properties `id` and `parentId`.
   */
  rawData: RawDataEntry<InternalDataType>[],
  /**
   * Maps arbitrary data from your raw data entries to the data you want included within tree nodes.
   */
  getNodeData: (
    rawDataEntry: RawDataEntry<InternalDataType>
  ) => InternalDataType
) {
  const intermediaryTree = generateIntermediaryTree(rawData, getNodeData);
  return generateFinalTree(intermediaryTree);
}

export type BiDirectionalTreeNode<InternalDataType> = Readonly<
  IntermediaryTreeNode<InternalDataType>
>;

export class BiDirectionalTree<InternalDataType> {
  /** Javascript can't store things by reference. So if we want a bidirectional tree, we need a normalized list of entries stored by their id. */
  private readonly tree: {
    [id: string]: BiDirectionalTreeNode<InternalDataType>;
  };
  private readonly rootNodeIds: string[] = [];

  constructor(
    rawData: RawDataEntry<InternalDataType>[],
    getNodeData: (
      rawDataEntry: RawDataEntry<InternalDataType>
    ) => InternalDataType
  ) {
    this.tree = generateIntermediaryTree(rawData, getNodeData);

    // Caching our root node ids for easier access later.
    this.rootNodeIds = Object.values(this.tree)
      .filter(node => node.isRootNode)
      .map(node => node.id);
  }

  getRootNodes(): BiDirectionalTreeNode<InternalDataType>[] {
    return this.rootNodeIds.map(id => this.tree[id]);
  }

  getNodeById(id: string): BiDirectionalTreeNode<InternalDataType> {
    // TODO: Error handling
    return this.tree[id];
  }

  getNodeChildren(
    node: BiDirectionalTreeNode<InternalDataType>
  ): BiDirectionalTreeNode<InternalDataType>[] {
    return node.childrenIds.map(childNodeId => this.tree[childNodeId]);
  }

  walkBack(
    node: BiDirectionalTreeNode<InternalDataType>
  ): BiDirectionalTreeNode<InternalDataType>[] {
    let currentNode = node;
    const nodes: BiDirectionalTreeNode<InternalDataType>[] = [currentNode];

    while (currentNode.parentId) {
      currentNode = this.getNodeById(currentNode.parentId);
      nodes.unshift(currentNode);
    }

    return nodes;
  }
}
