import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { StreamType } from '@wix/da-shared-react/pkg/Stream';
import { fetchNextOrders } from '../actions/points';

export const pointsStreamConfig: StreamInitConfig = {
  streamType: StreamType.WITH_OFFSET,
  autoFetch: true,
  itemsPerFetch: 10,
  fetchNextCallback: fetchNextOrders,
};
