import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import TextArea, {
  TextAreaProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextArea';
import {
  ContactUsFormValues,
  DmcaFormValues,
} from '../../../../redux/contactUs/types';

interface Props extends Omit<TextAreaProps, 'value' | 'onBlur' | 'onChange'> {
  id: string;
  name: keyof ContactUsFormValues | keyof DmcaFormValues;
}

const FormTextArea: React.FC<Props> = ({ name, isRequired, ...rest }) => {
  const { t } = useTranslation();
  const { control, errors, getValues } = useFormContext();
  const hasError = Boolean(errors?.[name]);
  const fieldValue = getValues(name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={fieldValue}
      rules={{
        required: isRequired,
      }}
      render={({ onChange: formProviderOnChange, onBlur, value }) => (
        <TextArea
          {...rest}
          autoComplete="off"
          validationResult={hasError ? 'error' : undefined}
          details={hasError ? t('common.form.errors.required') : undefined}
          value={value}
          onChange={ev => formProviderOnChange(ev.target.value)}
          onBlur={onBlur}
          noBottomSpacing
          isRequired={isRequired}
        />
      )}
    />
  );
};

FormTextArea.displayName = 'FormTextArea';

export default FormTextArea;
