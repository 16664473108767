import { createAction } from 'redux-actions';
import { PapiAddressV2, PapiSubscription } from '@wix/da-papi-types';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';

export const addAddress = createAction(`BILLING.ADD_ADDRESS`, formData => ({
  formData,
}));

export const addAddressToList = createAction(
  `BILLING.ADD_ADDRESS_SUCCESS`,
  (address: PapiAddressV2) => ({ address })
);

export const deleteAddress = createAction(
  `BILLING.DELETE_ADDRESS`,
  (addressId: number) => ({ addressId })
);

export const removeAddressFromList = createAction(
  `BILLING.DELETE_ADDRESS_SUCCESS`,
  (addressId: number) => ({ addressId })
);

export const deleteCard = createAction(
  `BILLING.DELETE_CARD`,
  (cardId: number) => ({
    cardId,
  })
);

export const removeCardFromList = createAction(
  `BILLING.DELETE_CARD_SUCCESS`,
  (cardId: number) => ({
    cardId,
  })
);

export const cancelCore = createAction(
  `BILLING.CANCEL_CORE`,
  (orderId: number) => ({
    orderId,
  })
);

export const removeCoreSubscription = createAction(
  `BILLING.CANCEL_CORE_SUCCESS`,
  () => ({})
);

export const cancelMemberSubscription = createAction(
  `BILLING.CANCEL_MEMBER_SUB`,
  (orderId: number) => ({
    orderId,
  })
);

export const removeMemberSubscription = createAction(
  `BILLING.CANCEL_MEMBER_SUB_SUCCESS`,
  (orderId: number) => ({
    orderId,
  })
);

export const fetchNextOrders = createCallbackActionCreator(
  'BILLING.FETCH_NEXT_ORDERS'
);

export const viewSubscriptionReceipt = createAction(
  `BILLING.VIEW_SUBSCRIPTION_RECEIPT`,
  (subscription: PapiSubscription) => ({ subscription })
);
