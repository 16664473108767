import { all, call, select, takeLeading } from 'redux-saga/effects';
import { PapiCheckout, } from '@wix/da-papi-types';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  stripeExpressButtonClicked,
} from '../actions/checkout';
import { getCheckout } from '../selectors/checkout';
import { getPayBiData } from '../helpers/checkout';
import { expressPaymentTypeToPaymentMethod } from '../helpers/stripe';

function* handleClick(action: ReturnType<typeof stripeExpressButtonClicked>) {
  const checkout: PapiCheckout = yield select(getCheckout);
  const hasCoreMembership: boolean = yield select(getCurrentUserHasCore);
  yield call(
    logBiEvent,
    getPayBiData(
      checkout.order,
      expressPaymentTypeToPaymentMethod(
        action.payload.event.expressPaymentType
      ),
      hasCoreMembership,
      checkout.giftUser
    )
  );
}

export default function* stripeSaga() {
  yield all([
    takeLeading(stripeExpressButtonClicked, handleClick),
  ]);
}
