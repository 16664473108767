import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import { getBackPageFromReferer } from '../helpers/backPage';
import { AppState } from '../types/store';

export const getForUserId = (state: AppState) => state?.foruserid;

export const getSubproductId = (state: AppState) =>
  state?.checkoutPage?.checkout?.order?.subproductId;

export const getReferer = (state: AppState) => getEnv(state).originalReferrer;

export const getIsPackagePickerCheckout = (state: AppState) =>
  getBackPageFromReferer(getReferer(state))?.titleKey ===
  'checkout.back.packagePicker';
