import { SagaIterator } from 'redux-saga';
import { call, all, takeLatest, put, select } from 'redux-saga/effects';
import i18next from 'i18next';
import ddt from '@wix/da-ddt';
import { XhrResponse, isApiError, requestPuppy } from '@wix/da-http-client';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { fetchNext } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import { getPageBiContext } from '@wix/da-shared-react/pkg/biLogger/redux/selectors';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';

import {
  PapiDacheckoutCreditcardAddressAdd,
  PapiDacheckoutCreditcardAddressDelete,
  PapiDacheckoutCreditcardDelete,
  PapiDacheckoutOrders,
  PapiDacheckoutSubscriptionsCancel,
  PapiRequestDacheckoutCreditcardAddressAdd,
  PapiRequestDacheckoutCreditcardAddressDelete,
  PapiRequestDacheckoutCreditcardDelete,
  PapiRequestDacheckoutSubscriptionsCancel,
} from '@wix/da-papi-types';
import { BILLING_ORDERS_STREAM } from '../../constants';
import {
  addAddress,
  deleteAddress,
  removeAddressFromList,
  deleteCard,
  removeCardFromList,
  addAddressToList,
  cancelCore,
  removeCoreSubscription,
  fetchNextOrders,
  cancelMemberSubscription,
  removeMemberSubscription,
  viewSubscriptionReceipt,
} from '../actions/billing';
import { getSubscriptionByOrderId } from '../selectors/billing';
import { PoniesModalType } from '../types/modals';
import {
  BiData,
  BiEvent,
  CancelSubscriptionConfirmBiEvent,
} from '@wix/da-bi/pkg/events';

const logger = ddt.createLogger('billingsaga');

function* addAddressSaga(action: ReturnType<typeof addAddress>) {
  if (!action.payload) {
    logger.error('Tried to add invalid address', action);
    return;
  }
  const { formData } = action.payload;

  const data: PapiRequestDacheckoutCreditcardAddressAdd = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    countryid: formData.countryId,
    stateid: formData.stateId,
    city: formData.city,
    zip_code: formData.postal,
    address1: formData.addressline1,
    address2: formData.addressline2,
  };

  const response: XhrResponse<PapiDacheckoutCreditcardAddressAdd> = yield call(
    requestPuppy,
    {
      method: 'post',
      url: '/creditcard/address/add',
      data,
    },
    undefined,
    'dacheckout'
  );

  if (
    !response ||
    isApiError(response) ||
    !response.success ||
    !response.cardAddress
  ) {
    yield putErrorNotification(i18next.t('common.error.addAddress'));
    return;
  }

  yield put(modalActions.popModal());
  yield put(addAddressToList(response.cardAddress));
}

function* deleteAddressSaga(action: ReturnType<typeof deleteAddress>) {
  if (!action.payload) {
    logger.error('Tried to unblock invalid addressId', action);
    return;
  }
  const { addressId } = action.payload;

  const data: PapiRequestDacheckoutCreditcardAddressDelete = {
    addressid: addressId,
  };

  const response: XhrResponse<PapiDacheckoutCreditcardAddressDelete> =
    yield call(
      requestPuppy,
      {
        method: 'post',
        url: '/creditcard/address/delete',
        data,
      },
      () => ({ success: true }),
      'dacheckout'
    );

  if (!response || isApiError(response) || !response.success) {
    yield putErrorNotification(i18next.t('common.error.deleteAddress'));
    return;
  }

  yield put(modalActions.popModal());
  yield put(removeAddressFromList(addressId));
}

function* deleteCardSaga(action: ReturnType<typeof deleteCard>) {
  if (!action.payload) {
    logger.error('Tried to unblock invalid cardId', action);
    return;
  }
  const { cardId } = action.payload;

  const data: PapiRequestDacheckoutCreditcardDelete = {
    storedcardid: cardId,
  };

  const response: XhrResponse<PapiDacheckoutCreditcardDelete> = yield call(
    requestPuppy,
    {
      method: 'post',
      url: '/creditcard/delete',
      data,
    },
    () => ({ success: true }),
    'dacheckout'
  );

  if (!response || isApiError(response) || !response.success) {
    yield putErrorNotification(i18next.t('common.error.deleteCard'));
    return;
  }

  yield put(modalActions.popModal());
  yield put(removeCardFromList(cardId));
}

function* cancelCoreSaga(action: ReturnType<typeof cancelCore>) {
  if (!action.payload) {
    logger.error('Tried to cancel invalid orderId', action);
    return;
  }
  const { orderId } = action.payload;

  if (orderId) {
    const biContext: ReturnType<typeof getPageBiContext> = yield select(
      getPageBiContext
    );
    const event: BiEvent = BiData<CancelSubscriptionConfirmBiEvent>({
      evid: 527,
      ...biContext,
      orderid: orderId,
    });
    yield call(logBiEvent, event);
  }

  const data: PapiRequestDacheckoutSubscriptionsCancel = {
    orderid: orderId,
  };

  const response: XhrResponse<PapiDacheckoutSubscriptionsCancel> = yield call(
    requestPuppy,
    {
      method: 'post',
      url: '/subscriptions/cancel',
      data,
    },
    () => {
      logger.info('cancelCoreSaga, fake response');
      return { subscription: null };
    },
    'dacheckout'
  );

  yield put(modalActions.popModal());

  if (!response || isApiError(response)) {
    logger.error('cancelCoreSaga', { response });
    yield putErrorNotification(i18next.t('common.error.cancelCore'));
    return;
  }

  yield put(removeCoreSubscription());

  const subscription = yield select(getSubscriptionByOrderId, orderId);
  if (subscription) {
    yield put(
      pushModal(PoniesModalType.CANCEL_SUBSCRIPTION, {
        params: { subscription, isCancelComplete: true },
      })
    );
  }
}

function* cancelMemberSubscriptionSaga(
  action: ReturnType<typeof cancelMemberSubscription>
) {
  if (!action.payload) {
    logger.error('Tried to cancel invalid orderId', action);
    return;
  }
  const { orderId } = action.payload;

  const data: PapiRequestDacheckoutSubscriptionsCancel = {
    orderid: orderId,
  };

  const response: XhrResponse<PapiDacheckoutSubscriptionsCancel> = yield call(
    requestPuppy,
    {
      method: 'post',
      url: '/subscriptions/cancel',
      data,
    },
    () => ({ subscription: null }),
    'dacheckout'
  );

  yield put(modalActions.popModal());

  if (!response || isApiError(response)) {
    logger.error('cancelMemberSubscriptionSaga', { response });
    yield putErrorNotification(i18next.t('common.error.cancelSubscription'));
    return;
  }

  yield put(removeMemberSubscription(orderId));

  const subscription = yield select(getSubscriptionByOrderId, orderId);
  if (subscription) {
    yield put(
      pushModal(PoniesModalType.CANCEL_SUBSCRIPTION, {
        params: { subscription, isCancelComplete: true },
      })
    );
  }
}

function* fetchMoreOrdersSaga(action: ReturnType<typeof fetchNext>) {
  const offset = yield select(
    withOffset.selectors.getNextOffset,
    BILLING_ORDERS_STREAM
  );

  const response: XhrResponse<PapiDacheckoutOrders> = yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/orders',
      params: { offset },
    },
    undefined,
    'dacheckout'
  );

  if (!response || isApiError(response)) {
    yield put(
      withOffset.actions.fetchFailure({
        streamId: BILLING_ORDERS_STREAM,
        fetchType: FetchType.Next,
      })
    );
    yield putErrorNotification(i18next.t('common.error.fetchMoreOrders'));
    return;
  }

  yield put(
    withOffset.actions.fetchSuccess({
      streamId: BILLING_ORDERS_STREAM,
      hasMore: response.hasMore ?? false,
      items: response.results ?? [],
      nextOffset: response.nextOffset ?? undefined,
    })
  );
}

function* viewSubscriptionReceiptSaga(
  action: ReturnType<typeof viewSubscriptionReceipt>
) {
  const { subscription } = action.payload;

  if (subscription.order) {
    yield put(
      pushModal(PoniesModalType.ORDER_DETAILS, {
        params: { order: subscription.order },
      })
    );
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(deleteCard, deleteCardSaga),
    takeLatest(addAddress, addAddressSaga),
    takeLatest(deleteAddress, deleteAddressSaga),
    takeLatest(cancelCore, cancelCoreSaga),
    takeLatest(cancelMemberSubscription, cancelMemberSubscriptionSaga),
    takeLatest(fetchNextOrders, fetchMoreOrdersSaga),
    takeLatest(viewSubscriptionReceipt, viewSubscriptionReceiptSaga),
  ]);
}
