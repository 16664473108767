import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CONTACT_PAGE_ANCHOR_JUMP_POINTS } from '@wix/da-url';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import {
  submitDmcaForm,
  resetReduxFormValues,
} from '../../../redux/contactUs/actions';
import { getFormSubmissionStatus } from '../../../redux/contactUs/selectors';
import {
  DmcaFormValues,
  DmcaNoticeType,
  FormSubmissionStatus,
  MenuTreeNode,
} from '../../../redux/contactUs/types';
import ContentBlock from '../partials/ContentBlock';
import ContentBlockHeading from '../partials/ContentBlockHeading';
import FormSectionSeparator from '../partials/FormSectionSeparator';
import { useDmcaForm } from './hooks';
import DmcaLegalChecklistBlock from './DmcaLegalChecklistBlock';
import DmcaSubmitterInfoBlock from './DmcaSubmitterInfoBlock';
import DmcaInfringedWorkBlock from './DmcaInfringedWorkBlock';
import { DmcaNoticeTypeContext } from './noticeTypeContext';
import s from './DmcaFormBlock.scss';

interface Props {
  selectedTopics: MenuTreeNode[];
  noticeType: DmcaNoticeType;
  formRefCallback?: React.RefCallback<HTMLFormElement>;
}

const DmcaFormBlock: React.FC<Props> = ({
  selectedTopics,
  noticeType,
  formRefCallback,
}) => {
  const { t } = useTranslation('contact');
  const formContext = useDmcaForm();
  const formSubmissionStatus = useSelector(getFormSubmissionStatus);

  const [isFormRevealed, setIsFormRevealed] = useState(false);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (data: DmcaFormValues) => {
      dispatch(submitDmcaForm(data, noticeType, selectedTopics));
    },
    [dispatch, noticeType, selectedTopics]
  );

  // reset the form after successful submission
  useEffect(() => {
    if (formSubmissionStatus === FormSubmissionStatus.Success) {
      dispatch(resetReduxFormValues());
      formContext.reset();
    }
  }, [dispatch, formSubmissionStatus, formContext]);

  return (
    <ContentBlock
      // ID so we can jump the page directly to the form
      heading={
        <ContentBlockHeading id={CONTACT_PAGE_ANCHOR_JUMP_POINTS.FORM}>
          Contact Us
        </ContentBlockHeading>
      }
      content={
        <Section className={s['content']}>
          {!isFormRevealed && (
            <TextButton
              variant="approval-green"
              size="small"
              onClick={() => setIsFormRevealed(true)}
            >
              {t('contact:dmcaForm.heading')}
            </TextButton>
          )}

          {isFormRevealed && (
            <DmcaNoticeTypeContext.Provider value={noticeType}>
              <FormProvider {...formContext}>
                <form
                  onSubmit={formContext.handleSubmit(handleSubmit)}
                  className={s['form']}
                  ref={formRefCallback}
                >
                  <DmcaInfringedWorkBlock />

                  <FormSectionSeparator />

                  <DmcaSubmitterInfoBlock />

                  <FormSectionSeparator />

                  <DmcaLegalChecklistBlock />
                  <div className={s['submit-and-afterword']}>
                    <SolidButton
                      size="small"
                      variant="approval-green"
                      type="submit"
                      disabled={
                        formSubmissionStatus === FormSubmissionStatus.InProgress
                      }
                      loading={
                        formSubmissionStatus === FormSubmissionStatus.InProgress
                      }
                    >
                      {t('contact:dmcaForm.submit.label')}
                    </SolidButton>
                  </div>
                </form>
              </FormProvider>
            </DmcaNoticeTypeContext.Provider>
          )}
        </Section>
      }
    />
  );
};
DmcaFormBlock.displayName = 'DmcaFormBlock';

export default DmcaFormBlock;
