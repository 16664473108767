import { combineActions, handleActions } from 'redux-actions';
import first from 'lodash/first';
import omit from 'lodash/omit';
import {
  initContactUsPage,
  initializeDmcaImageUploadEntry,
  removeDmcaImageUploadEntry,
  resetContactImageUpload,
  resetDmcaImageUpload,
  updateDmcaImageUploadEntry,
  uploadContactImage,
  uploadContactImageFailure,
  uploadContactImageSuccess,
  uploadDmcaImage,
  uploadDmcaImageFailure,
  uploadDmcaImageSuccess,
  submitContactForm,
  submitContactFormSuccess,
  submitDmcaForm,
  submitDmcaFormSuccess,
  submitDmcaFormFailure,
  submitContactFormFailure,
  resetReduxFormValues,
} from './actions';
import { reduxKey } from './constants';
import {
  ContactUsStoreSlice,
  FormSubmissionStatus,
  ImageUploadStatus,
} from './types';

const reducers = handleActions<ContactUsStoreSlice, any>(
  {
    [`${initContactUsPage}`]: (
      state,
      action: ReturnType<typeof initContactUsPage>
    ) => ({
      ...state,
      rawMenuData: action.payload.rawMenuData,
      initialMenuItemId: action.payload.initialMenuItemId,
      countries: action.payload.countries,
    }),
    [`${initializeDmcaImageUploadEntry}`]: (
      state,
      action: ReturnType<typeof uploadDmcaImage>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          entryUuid: action.payload.entryUuid,
          title: '',
          infringingUrl: '',
          originalUrl: '',
          previewDataUrl: undefined,
          uploadResult: undefined,
          status: ImageUploadStatus.NotStarted,
        },
      },
    }),
    [`${updateDmcaImageUploadEntry}`]: (
      state,
      action: ReturnType<typeof updateDmcaImageUploadEntry>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          ...state.dmcaFormImageUploads[action.payload.entryUuid],
          ...action.payload.data,
        },
      },
    }),
    [`${removeDmcaImageUploadEntry}`]: (
      state,
      action: ReturnType<typeof removeDmcaImageUploadEntry>
    ) => ({
      ...state,
      dmcaFormImageUploads: omit(
        state.dmcaFormImageUploads,
        action.payload.entryUuid
      ),
    }),
    [`${uploadDmcaImage}`]: (
      state,
      action: ReturnType<typeof uploadDmcaImage>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          ...state.dmcaFormImageUploads[action.payload.entryUuid],
          fileName: action.payload.file.fileObject.name,
          previewDataUrl: action.payload.file.fileBlob,
          status: ImageUploadStatus.InProgress,
        },
      },
    }),
    [`${resetDmcaImageUpload}`]: (
      state,
      action: ReturnType<typeof resetDmcaImageUpload>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          ...state.dmcaFormImageUploads[action.payload.entryUuid],
          fileName: undefined,
          previewDataUrl: undefined,
          uploadResult: undefined,
          status: ImageUploadStatus.NotStarted,
        },
      },
    }),
    [`${uploadDmcaImageSuccess}`]: (
      state,
      action: ReturnType<typeof uploadDmcaImageSuccess>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          ...state.dmcaFormImageUploads[action.payload.entryUuid],
          uploadResult: {
            name: action.payload.name,
            url: action.payload.url,
          },
          status: ImageUploadStatus.Success,
        },
      },
    }),
    [`${uploadDmcaImageFailure}`]: (
      state,
      action: ReturnType<typeof uploadDmcaImageFailure>
    ) => ({
      ...state,
      dmcaFormImageUploads: {
        ...state.dmcaFormImageUploads,
        [action.payload.entryUuid]: {
          ...state.dmcaFormImageUploads[action.payload.entryUuid],
          status: ImageUploadStatus.Failure,
        },
      },
    }),
    [`${resetContactImageUpload}`]: state => ({
      ...state,
      contactFormImageUpload: {
        fileName: undefined,
        uploadResult: undefined,
        status: ImageUploadStatus.NotStarted,
      },
    }),
    [`${uploadContactImage}`]: (
      state,
      action: ReturnType<typeof uploadContactImage>
    ) => ({
      ...state,
      contactFormImageUpload: {
        fileName: action.payload.file.fileObject.name,
        previewDataUrl: action.payload.file.fileBlob,
        status: ImageUploadStatus.InProgress,
      },
    }),
    [`${uploadContactImageSuccess}`]: (
      state,
      action: ReturnType<typeof uploadContactImageSuccess>
    ) => ({
      ...state,
      contactFormImageUpload: {
        ...state.contactFormImageUpload,
        uploadResult: {
          name: action.payload.name,
          url: action.payload.url,
        },
        status: ImageUploadStatus.Success,
      },
    }),
    [`${uploadContactImageFailure}`]: state => ({
      ...state,
      contactFormImageUpload: {
        ...state.contactFormImageUpload,
        status: ImageUploadStatus.Failure,
      },
    }),

    [`${combineActions(submitDmcaForm, submitContactForm)}`]: state => ({
      ...state,
      formSubmissionStatus: FormSubmissionStatus.InProgress,
    }),
    [`${combineActions(submitDmcaFormSuccess, submitContactFormSuccess)}`]:
      state => ({
        ...state,
        formSubmissionStatus: FormSubmissionStatus.Success,
      }),
    [`${combineActions(submitDmcaFormFailure, submitContactFormFailure)}`]:
      state => ({
        ...state,
        formSubmissionStatus: FormSubmissionStatus.Failure,
      }),
    [`${resetReduxFormValues}`]: state => {
      // Why pull the first dmca image entry and reuse its UUID instead of requiring the action pass through a new one?
      // The API of this action doesn't make sense to me at a first glance if it requires the use to pass through a UUID.
      //
      // I'd be more confused about what the UUID is needed for and go on a code tangent, myself.
      const dmcaFormEntry = first(Object.values(state.dmcaFormImageUploads));
      return {
        ...state,
        formSubmissionStatus: FormSubmissionStatus.AwaitingSubmission,
        dmcaFormImageUploads: dmcaFormEntry
          ? {
              [dmcaFormEntry.entryUuid]: {
                entryUuid: dmcaFormEntry.entryUuid,
                title: '',
                infringingUrl: '',
                originalUrl: '',
                previewDataUrl: undefined,
                uploadResult: undefined,
                status: ImageUploadStatus.NotStarted,
              },
            }
          : {},
        contactFormImageUpload: {
          fileName: undefined,
          uploadResult: undefined,
          status: ImageUploadStatus.NotStarted,
        },
      };
    },
  },
  {
    rawMenuData: [],
    dmcaFormImageUploads: {},
    contactFormImageUpload: {
      fileName: undefined,
      uploadResult: undefined,
      status: ImageUploadStatus.NotStarted,
    },
    formSubmissionStatus: FormSubmissionStatus.AwaitingSubmission,
    countries: [],
  }
);

export default {
  [reduxKey]: reducers,
};
