import { all, call, takeEvery, put, takeLeading } from 'redux-saga/effects';
import { Url } from '@wix/da-url';
import {
  PapiDacheckoutPaypalOrderAuthorizeOrCapture,
  PapiRequestDacheckoutPaypalAbandon,
} from '@wix/da-papi-types';
import { requestPuppy } from '@wix/da-http-client';
import {
  approvePaypalOrder,
  approvePaypalOrderFailed,
  approvePaypalSubscription,
  cancelPaypalOrder,
  setErrorCode,
} from '../actions/checkout';

function* handleApproveOrder(action) {
  const { data } = action.payload;
  const response: PapiDacheckoutPaypalOrderAuthorizeOrCapture = yield call(
    requestPuppy,
    {
      method: 'post',
      url: 'paypal/order_authorize_or_capture',
      data: {
        paypal_orderid: data.orderID,
      },
    },
    undefined,
    'dacheckout'
  );
  if (response && response.orderConfirmation?.ordergroupId) {
    window.location.assign(
      Url.checkoutCompleteLink(response.orderConfirmation.ordergroupId)
    );
  } else {
    yield put(setErrorCode(response.errorCode ?? 'paypal'));
    yield put(approvePaypalOrderFailed());
  }
}

function* handleApproveSubscription(
  action: ReturnType<typeof approvePaypalSubscription>
) {
  const { ordergroupId } = action.payload;
  window.location.assign(Url.checkoutCompleteLink(ordergroupId));
}

function* handleCancel(action: ReturnType<typeof cancelPaypalOrder>) {
  const { ordergroupId, isSubscription } = action.payload;

  if (isSubscription) {
    // Mark the order as abandoned
    const data: PapiRequestDacheckoutPaypalAbandon = {
      ordergroupid: ordergroupId,
    };
    yield call(
      requestPuppy,
      {
        method: 'post',
        url: 'paypal/abandon',
        data,
      },
      undefined,
      'dacheckout'
    );
  }

  // We expend the single use token to create a PayPal order,
  // if user cancels the order we need to obtain a new token.
  // Reloading the page is the easiest way to do that.
  window.location.reload();
}

export default function* paypalSaga() {
  yield all([
    takeLeading(approvePaypalOrder, handleApproveOrder),
    takeLeading(approvePaypalSubscription, handleApproveSubscription),
    takeEvery(cancelPaypalOrder, handleCancel),
  ]);
}
