// These values match what server expects, don't change them
// Note: 'stripe' is not really a payment method, it's what
// backend returns when order is made with Apple Pay / Google pay.
export enum PaymentMethod {
  CREDIT_CARD = 'card',
  PAYPAL = 'paypal',
  POINTS = 'points',
  BITPAY = 'bitpay',
  STRIPE = 'stripe',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
}

export enum CreditCardType {
  VISA = 1,
  AMEX,
  MASTERCARD,
  DISCOVER,
}

export enum ProductType {
  COMMISSION = 1,
  PREMIUM_FOLDER,
  CORE_PACKAGE,
  PCP,
  OTHER,
  PRINT,
  MEMBER_SUBSCRIPTION,
  ADOPTABLE,
}

export enum AdoptablePurchaseType {
  FIXED_PRICE = 1,
  OFFER,
}

export enum OrderStatus {
  PENDING_ARTIST_APPROVAL = 'pending_artist_approval',
  PENDING = 'pending',
  SHIPPED = 'shipped',
  REFUNDED = 'refunded',
  FAILED = 'failed',
}
