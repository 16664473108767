import React from 'react';
import { useTranslation } from 'react-i18next';
import FormCheckbox, { FormCheckboxProps } from '../../partials/FormCheckbox';

interface Props extends Omit<FormCheckboxProps, 'id' | 'name' | 'label'> {}

const ConsentsToJurisdictionCheckbox: React.FC<Props> = props => {
  const { t } = useTranslation('contact');
  return (
    <FormCheckbox
      id="consentsToJurisdiction"
      name="consentsToJurisdiction"
      label={t(
        'contact:dmcaForm.legalBlock.counterClaim.consentsToJurisdiction.label'
      )}
      {...props}
    />
  );
};

ConsentsToJurisdictionCheckbox.displayName = 'ConsentsToJurisdictionCheckbox';

export default ConsentsToJurisdictionCheckbox;
