import { all, fork } from 'redux-saga/effects';
import { sagas } from '@wix/da-shared-react/pkg/redux/shared';
import biLogger from '../sagas/biLogger';
import paypal from '../sagas/paypal';
import billing from '../sagas/billing';
import points from '../sagas/points';
import checkout from '../sagas/checkout';
import sales from '../sagas/sales';
import { contactUsSaga } from './contactUs/saga';
import stripe from '../sagas/stripe';

export default function* rootSaga() {
  yield all([
    // project
    fork(biLogger),
    fork(paypal),
    fork(billing),
    fork(points),
    fork(sales),
    fork(checkout),
    fork(contactUsSaga),
    fork(stripe),
    // shared
    ...sagas.map(fork),
  ]);
}
