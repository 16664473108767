import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { requestPuppy } from '@wix/da-http-client';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { fetchNext } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { SALES_ORDERS_STREAM } from '../../constants';
import { fetchNextSales } from '../actions/sales';
import { getSalesFetchParams } from '../selectors/sales';

function* fetchMoreSalesSaga(action: ReturnType<typeof fetchNext>) {
  const offset = yield select(
    withOffset.selectors.getNextOffset,
    SALES_ORDERS_STREAM
  );
  const fetchParams = yield select(getSalesFetchParams);

  const response = yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/customers_orders',
      params: {
        offset: offset + 1,
        ...fetchParams,
      },
    },
    undefined,
    'dacheckout'
  );

  if (response) {
    yield put(
      withOffset.actions.fetchSuccess({
        streamId: SALES_ORDERS_STREAM,
        hasMore: response.hasMore,
        items: response.results ?? [],
        nextOffset: response.nextOffset,
      })
    );
  } else {
    yield put(
      withOffset.actions.fetchFailure({
        streamId: SALES_ORDERS_STREAM,
        fetchType: FetchType.Next,
      })
    );
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLatest(fetchNextSales, fetchMoreSalesSaga)]);
}
