import type { ExpressPaymentType } from '@stripe/stripe-js';
import { PapiOrder } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { PaymentMethod } from '../types';

export function getApplePayRequestOptions(order: PapiOrder) {
  if (order.isRecurring) {
    return {
      recurringPaymentRequest: {
        paymentDescription: order.subproductName,
        managementURL: Url.account(),
        regularBilling: {
          label: order.subproductName,
          amount: order.totalInCents,
          recurringPaymentStartDate: new Date(),
          recurringPaymentIntervalCount: 1,
          recurringPaymentIntervalUnit:
            (order.corePackage?.interval as 'month' | 'year') ?? 'month',
        },
      },
    };
  }
  return undefined;
}

export function expressPaymentTypeToPaymentMethod(
  paymentType: ExpressPaymentType
) {
  if (paymentType === 'apple_pay') {
    return PaymentMethod.APPLE_PAY;
  }
  return PaymentMethod.GOOGLE_PAY;
}
