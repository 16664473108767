import { useEffect, useReducer } from 'react';
import { createAction } from 'redux-actions';
import type { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { usePreviousValue } from '@wix/da-hooks/pkg/usePreviousValue';
import { MenuTreeNode } from '../../../redux/contactUs/types';
import { resetReduxFormValues } from '../../../redux/contactUs/actions';

export enum Stage {
  AwaitingCategorySelection = 1,
  DrillingDown = 2,
  ReachedLeafWithoutForm = 3,
  ReachedLeafWithContactForm = 4,

  /**
   * There are technically three different forms included within this stage,
   * but they're similar enough that a single component can handle them.
   *
   * Any more and this enum will end up getting expanded
   */
  ReachedLeafWithDmcaForm = 5,
}

interface StepperState {
  /**
   * The current stage the stepper is at.
   */
  stage: Stage;

  /**
   *
   */
  lastSelectedItem: MenuTreeNode | null;

  /**
   * Whether the form at the bottom of the stepper is visible. This is necessary in addition to the
   * already tracked stage because we require the user to click another button to reveal the form.
   */
  showingForm: boolean;
}

export const selectCategory = createAction(
  `SELECT_CATEGORY`,
  (category: MenuTreeNode) => ({
    category,
  })
);

export const setLastSelectedMenuItem = createAction(
  `SET_LAST_SELECTED_MENU_ITEM`,
  (item: MenuTreeNode) => ({
    item,
  })
);

export const showForm = createAction(`SHOW_FORM`, () => ({}));

function getCorrectStepperStageForLeaf(leaf: MenuTreeNode) {
  if (!leaf.data?.contactEndpoint) {
    // No defined contact endpoint means we can't submit the form
    return Stage.ReachedLeafWithoutForm;
  }

  // Some leafs have a form other than the default contact form, so we'll check
  // the leaf's id for the special ones.
  switch (leaf.id) {
    case 'copyright_dmca_holder':
    case 'copyright_dmca_representative':
    case 'copyright_dmca_counter':
      return Stage.ReachedLeafWithDmcaForm;
    default:
      return Stage.ReachedLeafWithContactForm;
  }
}

function stepperReducer(state: StepperState, action: Action): StepperState {
  switch (action.type) {
    case `${selectCategory}`:
      return {
        stage: Stage.DrillingDown,
        lastSelectedItem: (action as ReturnType<typeof selectCategory>).payload
          .category,
        showingForm: false,
      };
    case `${setLastSelectedMenuItem}`:
      const lastItem = (action as ReturnType<typeof setLastSelectedMenuItem>)
        .payload.item;
      return {
        stage:
          lastItem && lastItem.isLeafNode
            ? getCorrectStepperStageForLeaf(lastItem)
            : Stage.DrillingDown,
        lastSelectedItem: lastItem,
        showingForm: false,
      };
    case `${showForm}`:
      return {
        ...state,
        showingForm: true,
      };
    default:
      break;
  }

  return state;
}

function getInitialStageForInitialMenuItem(
  initialItem: MenuTreeNode | null
): Stage {
  if (initialItem?.isRootNode) {
    return Stage.DrillingDown;
  } else if (initialItem?.isLeafNode) {
    return getCorrectStepperStageForLeaf(initialItem);
  } else {
    return Stage.AwaitingCategorySelection;
  }
}

export function useStepperState(initialItem: MenuTreeNode | null = null) {
  const initialStage = getInitialStageForInitialMenuItem(initialItem);
  const [state, dispatch] = useReducer(stepperReducer, {
    stage: initialStage,
    lastSelectedItem: initialItem,
    // Because we have a tendency to link directly to the DMCA form, we want the form to automatically expand in this case
    showingForm: initialStage === Stage.ReachedLeafWithDmcaForm,
  });

  const reduxDispatch = useDispatch();
  const previousStageValue = usePreviousValue(state.stage);

  useEffect(() => {
    if (
      previousStageValue &&
      previousStageValue >= Stage.ReachedLeafWithoutForm &&
      // Any drilldown/category change that the form submission status would care about
      // resets the `showingForm` boolean, so we'll check that instead of the current stage.
      //
      // What's the other option? Two new enum entries and switching the conditional to
      // `previousStageValue < Stage.ShowingDmcaForm`. Expanded below.
      //
      // We're using the `Stage.ReachedLeafWithDmcaForm` and `Stage.ReachedLeafWithContactForm` + the `showingForm`
      // value to determine when and which form to show. If we were to expand that enum, we would add two more entries
      // (e.g. `Stage.ShowingDmcaForm` + `Stage.ShowingContactForm`) to the pile which would complicate UI code
      // more. I don't think either one is better than the other, so I'm just going with the one that has fewer
      // changes for the moment.
      !state.showingForm
    ) {
      reduxDispatch(resetReduxFormValues());
    }
  }, [reduxDispatch, state.stage, previousStageValue, state.showingForm]);

  return [state, dispatch] as [StepperState, typeof dispatch];
}
