import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { pushGlobalConfirmationModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import last from 'lodash/last';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { getCsrfToken } from '@wix/da-shared-react/pkg/publicSession/selectors';
import escapeTextForBrowser from '@wix/da-shared-react/pkg/utils/xss/escapeTextForBrowser';
import { getFormSubmittedModalProps } from './helpers';
import {
  submitDmcaForm,
  submitContactForm,
  submitDmcaFormSuccess,
  submitContactFormSuccess,
  uploadDmcaImage,
  uploadDmcaImageSuccess,
  uploadDmcaImageFailure,
  uploadContactImage,
  uploadContactImageSuccess,
  uploadContactImageFailure,
  submitDmcaFormFailure,
  submitContactFormFailure,
} from './actions';
import { generateDmcaTicketBody } from './utils';
import {
  ContactFormImageUpload,
  DmcaFormImageUpload,
  MenuTreeNode,
} from './types';
import {
  getContactFormImageUpload,
  getDmcaFormImageUploads,
} from './selectors';

/**
 * The API expects the selected first, second, and last menu nodes for any given contact form
 * submission. This function converts an array of the selected nodes to the expected API values.
 */
function selectedTopicsToApiValues(selectedTopics: MenuTreeNode[]) {
  return {
    category: selectedTopics.length > 0 ? selectedTopics[0].id : undefined,
    subcategory:
      selectedTopics.length > 1 && !selectedTopics[1].isLeafNode
        ? selectedTopics[1].id
        : undefined,
    issue:
      selectedTopics.length > 1 && last(selectedTopics)?.isLeafNode
        ? last(selectedTopics)?.id
        : undefined,
  };
}

function* handleSubmitDmcaForm(action: ReturnType<typeof submitDmcaForm>) {
  const imageUploadEntries: { [key: string]: DmcaFormImageUpload } =
    yield select(getDmcaFormImageUploads);
  const { noticeType, selectedTopics, data: formData } = action.payload;

  const data = {
    contact_endpoint: last(selectedTopics)?.data.contactEndpoint,
    subject: `DMCA Request: ${noticeType.toUpperCase()}`,
    body: generateDmcaTicketBody(
      formData,
      Object.values(imageUploadEntries),
      noticeType
    ),
    attachment_name: Object.values(imageUploadEntries)
      .map(entry => entry.uploadResult?.name)
      .filter(value => !!value),
    attachment_url: Object.values(imageUploadEntries)
      .map(entry => entry.uploadResult?.url)
      .filter(value => !!value),
    ...selectedTopicsToApiValues(selectedTopics),
  };

  const response = yield call(
    requestPuppy,
    {
      url: 'form',
      method: 'post',
      data,
    },
    () => ({
      success: true,
      ticketUrl: 'http://node.deviantart.lan:3000',
    }),
    'dacontact'
  );

  if (!response || isApiError(response)) {
    yield all([
      put(submitDmcaFormFailure()),
      putErrorNotification(
        i18next.t(
          'We had trouble submitting the DMCA form for you. Please try again.'
        )
      ),
    ]);
  } else {
    yield put(submitDmcaFormSuccess());
    yield put(
      pushGlobalConfirmationModal(
        getFormSubmittedModalProps(response.ticketUrl)
      )
    );
  }
}

function* handleSubmitContactForm(
  action: ReturnType<typeof submitContactForm>
) {
  const imageUpload: ContactFormImageUpload = yield select(
    getContactFormImageUpload
  );
  const {
    data: { title, issueDescription },
    selectedTopics,
  } = action.payload;

  const data = {
    contact_endpoint: last(selectedTopics)?.data.contactEndpoint,
    subject: escapeTextForBrowser(title),
    body: escapeTextForBrowser(issueDescription),
    attachment_name: imageUpload.uploadResult
      ? [imageUpload.uploadResult?.name]
      : [],
    attachment_url: imageUpload.uploadResult
      ? [imageUpload.uploadResult?.url]
      : [],
    ...selectedTopicsToApiValues(selectedTopics),
  };

  const response = yield call(
    requestPuppy,
    {
      url: 'form',
      method: 'post',
      data,
    },
    () => ({
      success: true,
      // for testing the optional..ity(?) of ticketUrl
      // ticketUrl: 'http://node.deviantart.lan:3000',
    }),
    'dacontact'
  );

  if (!response || isApiError(response)) {
    yield all([
      put(submitContactFormFailure()),
      putErrorNotification(
        i18next.t(
          'We had trouble submitting the contact form for you. Please try again.'
        )
      ),
    ]);
  } else {
    yield put(submitContactFormSuccess());
    yield put(
      pushGlobalConfirmationModal(
        getFormSubmittedModalProps(response.ticketUrl)
      )
    );
  }
}

function* sendUploadImageRequest(
  fileObject: File,
  onSuccess: (data: { name: string; url: string }) => Generator,
  onError: (response: any) => Generator
) {
  const formData = new FormData();
  formData.append('attachment_file', fileObject, fileObject.name);
  formData.append('csrf_token', yield select(getCsrfToken));

  const response = yield call(
    requestPuppy,
    {
      url: `upload`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    () => ({
      name: 'File Upload Response',
      url: 'https://source.unsplash.com/random',
    }),
    'dacontact'
  );

  if (!response || isApiError(response) || !response.url) {
    yield onError(response);
  } else {
    yield onSuccess(response);
  }
}

function* handleUploadDmcaImage(action: ReturnType<typeof uploadDmcaImage>) {
  const {
    entryUuid,
    file: { fileObject },
  } = action.payload;

  yield sendUploadImageRequest(
    fileObject,
    function* (data) {
      yield put(uploadDmcaImageSuccess(action.payload.entryUuid, data));
    },
    function* () {
      yield put(uploadDmcaImageFailure(entryUuid));
    }
  );
}

function* handleUploadContactImage(
  action: ReturnType<typeof uploadContactImage>
) {
  const {
    file: { fileObject },
  } = action.payload;

  yield sendUploadImageRequest(
    fileObject,
    function* (data) {
      yield put(uploadContactImageSuccess(data));
    },
    function* () {
      yield put(uploadContactImageFailure());
    }
  );
}

export function* contactUsSaga() {
  yield all([
    takeLatest(submitDmcaForm, handleSubmitDmcaForm),
    takeLatest(submitContactForm, handleSubmitContactForm),
    takeLatest(uploadDmcaImage, handleUploadDmcaImage),
    takeLatest(uploadContactImage, handleUploadContactImage),
  ]);
}
